import { Group, Text, rem } from '@mantine/core';
import { IconUpload, IconPhoto, IconX } from '@tabler/icons-react';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';

export function ImageDropZone({ onFileSelect, selectedFile }) {

    return (
        <Dropzone
            onDrop={(files) => {
                onFileSelect(files[0]); // Pass the first selected file to the parent
            }}
            onReject={(files) => console.log('rejected files', files)}
            maxSize={3 * 1024 ** 2}
            accept={IMAGE_MIME_TYPE}

        >
            <Group justify="center" gap="xs" style={{ pointerEvents: 'none' }}>
                <Dropzone.Accept>
                    <IconUpload
                        style={{ width: rem(30), height: rem(55), color: 'var(--mantine-color-blue-6)' }}
                        stroke={1.5}
                    />
                </Dropzone.Accept>

                <Dropzone.Reject>
                    <IconX
                        style={{ width: rem(30), height: rem(55), color: 'var(--mantine-color-red-6)' }}
                        stroke={1.5}
                    />
                </Dropzone.Reject>

                <Dropzone.Idle>
                    <IconPhoto
                        style={{ width: rem(30), height: rem(69), color: 'var(--mantine-color-dimmed)' }}
                        stroke={1.5}
                    />
                </Dropzone.Idle>

                <div>
                    {
                        selectedFile ? (
                            <Text size="xs" inline>
                                {selectedFile.name} Image Selected
                            </Text>
                        ) : (
                            <Text size="xs" inline>
                                Drag Custom Icon or Click to upload
                            </Text>
                        )
                    }
                </div>
            </Group>
        </Dropzone >
    );
}
