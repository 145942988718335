export function formatDateRange(value) {
  const options = { month: "short" }; // Options for formatting month as short name

  const startDay = new Date(value[0]).getDate();
  const startMonth = new Date(value[0]).toLocaleDateString("en-US", options);

  const endDay = new Date(value[1]).getDate();
  const endMonth = new Date(value[1]).toLocaleDateString("en-US", options);

  const formattedRange = `${startDay} ${startMonth} - ${endDay} ${endMonth}`;

  return formattedRange;
}
