import "./Home.css";
import { RouterProvider } from "react-router-dom";
import router from "./routes/routes";
import { Context } from "./context/Context";
import "@mantine/core/styles.css";
import { createTheme, MantineProvider } from "@mantine/core";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import "@mantine/dates/styles.css";
import { ModalsProvider } from "@mantine/modals";

function App() {
  const { theme } = useContext(Context);
  const themeCo = createTheme({});

  return (
    <MantineProvider theme={themeCo} defaultColorScheme={theme}>
      <ModalsProvider>
        <RouterProvider router={router} />
      </ModalsProvider>
      <ToastContainer />
    </MantineProvider>
  );
}

export default App;
