import { instance, lttlInstance } from "..";

export const appRegistrationService = {
  async getApp() {
    return (await instance.get("/appRegistration/getApp.php")).data;
  },

  async registerApplication(data) {
    return (await instance.post("/appRegistration/create.php", data)).data;
  },

  async createAssociation(data) {
    return (await lttlInstance.post("/assets-link", data)).data;
  },
};
