import { Loader, Select } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { IoIosArrowDropdown } from "react-icons/io";
import { domainService } from '../../service/module/domainService';
import { useContext } from 'react';
import { Context } from '../../context/Context';
const ONE_HOUR = 60 * 60 * 1000;


export function UrlPrefix({ setSelection, currentSelection, width = "200px", label = "" }) {

    const { dashboardConfig: { AppURL } } = useContext(Context);

    const { data, isError, isLoading } = useQuery({
        queryKey: ["getDomains"],
        queryFn: () => domainService.getDomains(),
        select: (domains) => domains.filter((domain) => domain.is_verified).map(domain => ({ value: domain.domain_name, label: domain.domain_name, isVerified: domain.is_verified })),
        staleTime: ONE_HOUR * 24,
        cacheTime: ONE_HOUR * 24
    })


    if (isLoading) {
        return <Loader size={"sm"}></Loader>
    }

    // Prepare the data for Select component
    let selectData = data || [];

    selectData = [{ value: AppURL, label: AppURL, isVerified: true }, ...selectData];

    if (data?.length > 0 && !currentSelection) {
        setSelection(selectData[0].value);
    }

    if (isError) {
        return <>something went wrong </>
    }


    return (
        <div className=' flex items-center gap-1'>
            <Select
                rightSection={<IoIosArrowDropdown color='var(--mantine-color-blue-filled-hover)' size={20}></IoIosArrowDropdown>}
                comboboxProps={{ withinPortal: false, withArrow: false }}
                styles={(theme) => ({
                    input: {
                        width: width,
                        fontSize: '15px',
                        color: theme.colors.blue[7],
                    },
                })}
                label={label}
                variant='default'
                checkIconPosition="left"
                data={selectData}
                onChange={(value) => setSelection(value)}
                placeholder="Pick value"
                value={currentSelection ?? data[0]?.value}
                defaultValue={data[0]?.value}
                radius={"sm"}

            />
        </div>
    );
}