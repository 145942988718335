const sortConfigurations = {
  creationDateAsc: { sortField: "created_at", ascending: true, isDate: true },
  creationDateDesc: { sortField: "created_at", ascending: false, isDate: true },
  expirationDateAsc: {
    sortField: "expiry_date",
    ascending: true,
    isDate: true,
  },
  expirationDateDesc: {
    sortField: "expiry_date",
    ascending: false,
    isDate: true,
  },
  titleAsc: { sortField: "title", ascending: true, isDate: false },
  titleDesc: { sortField: "title", ascending: false, isDate: false },
};

// Step 2: Update the `sortData` Function
export const sortData = (data, sortKey, sortConfig = sortConfigurations) => {
  sortConfig = Object.assign(sortConfig, sortConfigurations);

  const config = sortConfig[sortKey] || {};
  const { sortField, ascending, isDate } = config;

  if (!sortField) {
    return data; // Return unsorted data if no valid sortField is found
  }

  const compare = (a, b) => {
    if (isDate) {
      const aValue = new Date(a[sortField]).getTime();
      const bValue = new Date(b[sortField]).getTime();
      return ascending ? aValue - bValue : bValue - aValue;
    } else {
      const aValue = a[sortField] || "";
      const bValue = b[sortField] || "";
      return ascending
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }
  };

  return [...data].sort(compare);
};
