import { instance } from "..";

export const linkService = {
  async getLinks() {
    const res = await instance.get("/links/list.php");
    return res.data;
  },

  async createLink(linkData) {
    const res = await instance.post("/links/create.php", linkData);
    return res.data;
  },

  async getLinkById(id) {
    const res = await instance.get(`/links/get.php`, {
      params: { id },
    });
    return res.data;
  },

  async updateLinkById(id, linkData) {
    const res = await instance.put(`/links/update.php?id=${id}`, linkData);
    return res.data;
  },

  async deleteLinkById(id) {
    const res = await instance.delete(`/links/delete.php?id=${id}`);
    return res.data;
  },
  async deleteAllLinkWithChannelId(id) {
    return await instance.delete(
      `/links/deleteAllLinksWithChannel.php?channel_id=${id}`
    );
  },
};
