import { StepNavigation } from "./StepNavigation";

export const Step = ({ content, onNext, onPrev, isLastStep, currentStep, stepNavigator }) => {

    return (
        <div className=" flex flex-col gap-2">
            {content}
            {
                stepNavigator ? stepNavigator : (
                    <StepNavigation currentStep={currentStep} onNext={onNext} onPrev={onPrev} isLastStep={isLastStep} />
                )
            }
        </div>
    );
};