const rules = {
  minLength: (value, length, message) => value.length > length || `${message}`,

  maxLength: (value, length, message) => value.length < length || `${message}`,

  isNumber: (value) => !isNaN(value) || `Must be a number`,

  maxValue: (value, max, message) => {
    return Number(value) <= max || `${message}`;
  },
  minValue: (value, min, message) => {
    return Number(value) >= min || `${message}`;
  },

  required: (value) => value.trim() !== "" || `This field is required`,

  validate: (value, regex, message) => {
    const hindiRegex = /[\u0900-\u097F]/;
    const httpOrWwwRegex = /^(https?:\/\/|www\.)[a-zA-Z0-9\-._~%]+/;
    if (httpOrWwwRegex.test(value) && hindiRegex.test(value)) {
      return true; // Always return true for Hindi URLs
    }
    return regex.test(value) || `${message ? message : "Must be a valid URL"}`;
  },
};

export const validateRules = (value, validationRules) => {
  for (const [rule, params] of Object.entries(validationRules)) {
    const ruleFunction = rules[rule];
    if (ruleFunction) {
      const result = Array.isArray(params)
        ? ruleFunction(value, ...params)
        : ruleFunction(value, params);
      if (result !== true) {
        return result; // Return the error message if the validation fails
      }
    }
  }
  return true; // All validations passed
};
