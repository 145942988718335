import React, { useState } from 'react'
import Header from '../components/DynamicLink/Header'
import Wrapper from '../components/Wraper'
import { UrlPrefix } from '../components/shared/UrlPrefix'
import FilterByDate from '../components/FilterByDate'
import { DynamicLinkList } from '../components/DynamicLink/DynamicLinksLIst'
import PrimaryDialog from '../components/shared/PrimaryModal'
import { AddDynamicLink } from '../components/DynamicLink/AddDynamicLink'
import { useQuery } from '@tanstack/react-query'
import { appRegistrationService } from '../service/module/appRegistrationService'
import { deepLinkService } from '../service/module/deepLinkService'
import { useSearchParams } from 'react-router-dom'
import { filterByDate } from '../helper/filterByDate'
import { LoadingOverlay } from '@mantine/core'
import { EditDynamicLink } from '../components/DynamicLink/EditDynamicLink'
import { DashboardSkeleton } from '../components/Skeleton'

export function DynamicLink() {

    const [open, setOpen] = useState(false)
    const [openDynamicLink, setOpenDynamicLink] = useState(false)
    const handleClose = () => setOpen(prv => !prv)
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [editLink, setEditLink] = useState();

    const [searchParams] = useSearchParams();

    const dateTo = searchParams.get('filterTo');
    const dateFrom = searchParams.get('filterFrom');

    const { data: apps, isLoading: isLoadingApps, isError: isErrorApps } = useQuery({
        queryKey: ['getApp'],
        queryFn: () => appRegistrationService.getApp(),
        select: (apps) => {
            const accumulatedApps = apps.reduce((acu, app) => {
                if (app.os === 'Android') {
                    acu.android.push({ value: String(app.id), label: app.app_name, app_identifier: app.app_identifier });
                } else if (app.os === 'IOS') {
                    acu.ios.push({ value: String(app.id), label: app.app_name, app_identifier: app.app_identifier, store_id: app.store_id });
                }
                return acu;
            }, { android: [], ios: [] });

            return accumulatedApps; // Make sure to return the accumulated data
        },
        enabled: openDynamicLink || !!editLink
    })

    const { data: domain, isLoading: isLoadingDomain, isError: isErrorDomain } = useQuery({
        queryKey: ['getDomain', selectedDomain],
        queryFn: () => deepLinkService.getDeepLinkByDomain(selectedDomain),
    })

    const filteredData = filterByDate(domain, [dateTo, dateFrom]);

    if (isLoadingDomain && !selectedDomain) return <DashboardSkeleton />

    if (isErrorApps || isErrorDomain) {
        return <div>Something Went Wrong</div>
    }

    return (
        <Wrapper>
            <div>
                <Header setOpenDynamicLink={setOpenDynamicLink} open={open} setOpen={setOpen} handleClose={handleClose} />

                <PrimaryDialog size="lg" open={openDynamicLink} onClose={() => setOpenDynamicLink(prv => !prv)} title={<span className=" text-xl font-bold">Create Dynamic Link</span>}>
                    {
                        isLoadingApps ? (
                            <LoadingOverlay visible={isLoadingApps} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
                        ) : (
                            <AddDynamicLink selectedDomain={selectedDomain} closeModal={setOpenDynamicLink} apps={apps} />
                        )
                    }
                </PrimaryDialog>

                <PrimaryDialog size="lg" open={editLink} onClose={() => setEditLink(null)} title={<span className=" text-xl font-bold">Edit Dynamic Link</span>}>
                    {
                        isLoadingApps ? (
                            <LoadingOverlay visible={isLoadingApps} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
                        ) : (
                            <EditDynamicLink selectedDomain={selectedDomain} closeModal={() => setEditLink(null)} apps={apps} data={editLink} />
                        )
                    }
                </PrimaryDialog>


                <div className=' py-10'>
                    <div className=" py-3">
                        <div className='flex justify-between'>
                            <UrlPrefix currentSelection={selectedDomain} setSelection={setSelectedDomain} />
                            <FilterByDate />
                        </div>
                    </div>


                    {selectedDomain && (
                        <DynamicLinkList editLink={setEditLink} domains={filteredData} />
                    )}

                </div>
            </div>
        </Wrapper>
    )
}

