import { ActionIcon, Badge, Button, Text, Tooltip } from "@mantine/core";
import {
  IconCalendarEvent,
  IconEdit,
  IconBrandGoogleAnalytics,
  IconDownload,
  IconLink,
  IconTrash,
} from "@tabler/icons-react";
import Label from "../shared/Label";
import QrCodePreview from "./QrCodePreview";
import { useState } from "react";
import PrimaryDialog from "../shared/PrimaryModal";
import EditView from "./EditView";
import { useAlert } from "../../context/AlertContext";
import { linkService } from "../../service/module/linkService";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { formatDate } from "../../helper/formatDate";
import { FaRegCalendarTimes } from "react-icons/fa";
import CpButton from "../Links/CpButton";
import { isExpired } from "../../helper/isExpired";
import { IoReturnDownForwardSharp } from "react-icons/io5";


const QrCodeCard = ({ onClick, item, setUrl }) => {
  const { title } = item;
  const [downloadFun, setDownloadFun] = useState(null);
  const { showAlert } = useAlert();
  const [edit, setEdit] = useState(false)
  const queryClient = useQueryClient();
  const handleQrCode = () => {
    downloadFun?.downloadImage(title);
  };
  const downloadQrCode = (qrCode) => {
    setDownloadFun(qrCode);
  };


  const deleteItem = async (id) => {

    try {
      const confirmed = await showAlert({
        title: 'Delete Confirmation',
        message: 'Are you sure you want to delete this item?',
        positiveLabel: 'Delete',
        negativeLabel: 'Cancel',
        positiveStyle: {
          backgroundColor: "red",
          color: "white"
        }
      });

      if (confirmed) {
        await linkService.deleteLinkById(id)
        toast.info("QR Deleted Successful")
        queryClient.invalidateQueries({ queryKey: ['getQR'] })
      }
    } catch (error) {
      toast.error("Something went wrong");
    }

  }

  const expired = isExpired(item.expiry_date)

  return (
    <div className="p-4 px-8 mt-4 bg-white dark:bg-softPrimary shadow-lg flex-col mx-4 rounded-md">
      <div className="flex items-center justify-between">
        <Label label={title} className={"!text-2xl overflow-hidden"} />
        <div className="flex gap-2 items-center">
          <CpButton disabled={expired} link={`https://${item.short_code_domain}/${item.short_code}`} />
          <Tooltip label={"Edit"} withArrow position="top">
            <ActionIcon
              className="dark:bg-primary dark:hover:bg-softPrimary dark:hover:text-white "
              variant="default"
              disabled={expired}
            >
              <IconEdit onClick={() => setEdit(true)} className="dark:text-white text-black" />
            </ActionIcon>
          </Tooltip>

          <Tooltip label={"Download QR Code"} withArrow position="top">
            <ActionIcon
              onClick={handleQrCode}
              className="dark:bg-primary dark:hover:bg-softPrimary dark:hover:text-white "
              variant="default"
            >
              <IconDownload className="dark:text-white text-black" />
            </ActionIcon>
          </Tooltip>

          <Tooltip label={"Delete"} withArrow position="top">
            <ActionIcon
              variant="default"
              className="dark:bg-primary dark:hover:bg-softPrimary dark:text-white "
              onClick={() => deleteItem(item.id)}
            >
              <IconTrash className="dark:text-white text-red-600" />
            </ActionIcon>
          </Tooltip>

          <Tooltip label={"View Analytics"} withArrow position="top">
            <Button
              variant="outline"
              size="xs"
              className="dark:bg-primary flex dark:hover:bg-softPrimary dark:text-white bg-softPrimary "
              onClick={() => setUrl({ short_code: item.short_code, title: item?.title })}
            >
              <IconBrandGoogleAnalytics size={18} className="dark:text-white me-2 " />
              View Analytics
            </Button>
          </Tooltip>
        </div>
      </div>

      <div className="mt-2 flex align-start gap-4 w-full">
        <div>
          <QrCodePreview
            width={140}
            url={`https://${item.short_code_domain}/${item.short_code}`}
            isHideLabel={true}
            id={item.id}
            downloadQrCode={downloadQrCode}
            title={item.title}
            fabIcon={item.logo_url}
          />
        </div>
        <div className="w-full overflow-hidden">
          <p className=" dark:text-white font-semibold">Website</p>

          <div className="flex gap-2 items-center">
            <IoReturnDownForwardSharp size={18} className=" inline" />
            <span className="hover:underline cursor-pointer inline-block">
              {item.long_url}
            </span>
          </div>

          <div className="flex gap-4 items-center mt-6">

            <div className="flex gap-1 items-center">
              <IconCalendarEvent width={18} className=" dark:text-white text-black/60" />
              <p className="text-sm text-black/60 dark:text-white">{formatDate(item.created_at)}</p>
            </div>

            <div className="flex gap-1 items-center">
              <FaRegCalendarTimes width={16} className="dark:text-white text-black/60 " />
              <p className="text-sm text-black/60 dark:text-white">{formatDate(item.expiry_date)}</p>
            </div>

            <div className="flex gap-1 items-center">
              <IconLink size={19} className=" text-black/60 dark:text-white" />
              <a
                target="_blank"
                href={`https://${item.short_code_domain}/${item.short_code}`}
                className="text-sm  text-brand dark:text-brand hover:underline font-semibold "
                rel="noreferrer"
              >
                {`https://${item.short_code_domain}/${item.short_code}`}
              </a>
              {expired &&
                <Badge color="rgb(217, 83, 79)" size="xs">Expired</Badge>
              }
            </div>
          </div>
        </div>
      </div>

      <PrimaryDialog open={edit} onClose={setEdit} title={<Text> Edit QR Code</Text>} >
        <EditView close={setEdit} editData={item} />
      </PrimaryDialog>

    </div>
  );
};

export default QrCodeCard;
