export const generateResponse = (input) => {
  const response = {
    domain: input.domain,
    url: input.url,
    deep_link_url: input.deep_link_url,
    deep_link_name: input.deep_link_name,
    // expiry_days: input.expiry,
    // short_url: input.shortUrl,

    // Non Required Field
    meta_title: input?.meta_title,
    meta_description: input?.meta_description,
    meta_profile_image: input?.meta_image,
    utm_source: input?.utm_source,
    utm_medium: input?.utm_medium,
    utm_campaign: input?.utm_campaign,
    open_android_in_browser: false,
    open_ios_in_browser: false,
    app_version: input?.app_version,
  };

  if (
    input.android_link_behavior === "android_link_browser" &&
    input.apple_link_behavior === "apple_link_browser"
  ) {
    response.open_android_in_browser = true;
    response.open_ios_in_browser = true;
    return response;
  }

  // link behavior is android_link_mobile || android_link_browser
  if (input.android_link_behavior === "android_link_mobile") {
    response.android_app_id = input.android_app_id;

    response.android_custom_url = input.android_custom_url; // Save the selection.

    if (input.android_custom_url === "android_custom_url") {
      response.android_redirect_url = input.android_redirect_url;
    }
  } else {
    response.open_android_in_browser = true;
  }

  // link behavior is apple_link_mobile || apple_link_browser
  if (input.apple_link_behavior === "apple_link_mobile") {
    response.ios_app_id = input.apple_app_id;

    response.ios_custom_url = input.apple_custom_url; // Save the selection

    if (input.apple_custom_url === "apple_custom_url") {
      response.ios_redirect_url = input.apple_redirect_url;
    }
  } else {
    response.open_ios_in_browser = true;
  }

  return response;
};
