import axios from "axios";
import { instance } from "..";
import { basicToken } from "../../helper/basicAuth";
export const userService = {
  async getUsers(userName, password) {
    const baseURL = process.env.REACT_APP_API_URL;
    const token = basicToken(userName, password);
    const res = await axios.get(`${baseURL}/users/list.php`, {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    return res.data;
  },

  async getUserById(id) {
    const res = await instance.get("/users/get.php", {
      params: { id },
    });
    return res.data;
  },
};
