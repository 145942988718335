import React from "react";
import GrafanaDashboard from "../components/GrafanaDashboard";

const Analytics = () => {
  return (
    <div>
      <GrafanaDashboard />
    </div>
  );
};

export default Analytics;
