import React, { Suspense } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Analytics from "../pages/Analytics";
import Dashboard from "../Home";
import Login from "../pages/Login";
import Report from "../pages/Report";
import ErrorPage from "../pages/ErrorPage";
import { PersonalizeLinks } from "../pages/PersonalizeLinks.jsx";
import { DashboardSkeleton } from "../components/Skeleton.jsx";
import { DynamicLink } from "../pages/DynamicLink.jsx";
import { AddDomain } from "../pages/AddDomain.jsx";

const Links = React.lazy(() => import("../pages/Links"));
const QrCode = React.lazy(() => import("../pages/QrCode.jsx"));
const Campaign = React.lazy(() => import("../pages/Campaign.jsx"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/dashboard" replace />, // Redirect to /dashboard
  },
  {
    path: "/dashboard",
    element: <Dashboard />,

    children: [
      {
        path: "/dashboard/*",
        element: <Analytics />,
      },
      {
        path: "/dashboard/links",
        element: (
          <Suspense fallback={<DashboardSkeleton />}>
            <Links />
          </Suspense>
        ),
      },
      {
        path: "/dashboard/dynamic-link",
        element: (
          <Suspense fallback={<DashboardSkeleton />}>
            <DynamicLink />
          </Suspense>
        ),
      },
      {
        path: "/dashboard/qr/new",
        element: (
          <Suspense fallback={<DashboardSkeleton />}>
            <QrCode />
          </Suspense>
        ),
      },
      {
        path: "/dashboard/campaign/new",
        element: (
          <Suspense fallback={<DashboardSkeleton />}>
            <Campaign />
          </Suspense>
        ),
      },
      {
        path: "/dashboard/tail-ends",
        element: (
          <Suspense fallback={<DashboardSkeleton />}>
            <PersonalizeLinks />
          </Suspense>
        ),
      },
      {
        path: "/dashboard/report",
        element: <Report />,
      },
      {
        path: "/dashboard/AddDomain",
        element: (
          <Suspense fallback={<DashboardSkeleton />}>
            <AddDomain />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/*",
    element: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

export default router;
