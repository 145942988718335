export function isExpired(expiryDate) {
  // Get the current date
  const currentDate = new Date();

  // Convert the expiryDate to a Date object
  const expiry = new Date(expiryDate);

  // Compare the dates
  return currentDate > expiry;
}
