import { instance } from "..";

export const domainService = {
  async getDomains() {
    const res = await instance.get("/domain/getDomains.php");
    return res.data;
  },

  async addDomain(data) {
    return (await instance.post("/domain/addDomain.php", data)).data;
  },

  async validateDomain(data) {
    return (await instance.post("/domain/verifyDomain.php", data)).data;
  },

  async deleteDomain(domainName) {
    return await instance.delete(
      `/domain/deleteDomain.php?domainName=${domainName}`
    );
  },
};
