import React from "react";
import { GiSandsOfTime } from "react-icons/gi";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

export const RenderStatus = ({ status }) => {

  switch (status) {
    case "pending":
      return (
        <div className=" flex gap-1 items-center">
          <GiSandsOfTime color="orange" />
          <span className=" text-orange-400">Pending</span>
        </div>
      );
    case "verified":
      return (
        <div className=" flex gap-1 items-center">
          <FaCheckCircle size={16} color="green" />
          <span className=" text-green-700">Success</span>
        </div>
      );
    case "unapproved":
      return (
        <div className=" flex gap-1 items-center">
          <MdCancel size={18} color="red" />
          <span className=" text-red-600">Unapproved</span>
        </div>
      );
    default:
      return <span>Unknown</span>;
  }
};
