
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Radio } from '@mantine/core';

export const RadioControl = ({
    label,
    name,
    size = 'xs',
    rules = {},
    defaultChecked = false,
    value,
    onChangeEvent = (v) => null,
}) => {
    const { control } = useFormContext();
    const {
        field: { onChange, onBlur, ref },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules,
    });

    return (
        <Radio
            label={label}
            value={value}
            size={size}
            error={error ? error.message : null}
            onChange={onChange}
            onBlur={onBlur}
            checked={defaultChecked}
            ref={ref}
        />
    );
};

