export const generateAndroidVerificationJSON = ({ package_name, sha }) => {
  return `
    [
        {
          "relation": [
            "delegate_permission/common.handle_all_urls"
          ],
          "target": {
            "namespace": "android_app",
            "package_name": "${package_name}",
            "sha256_cert_fingerprints": [
              "${sha}"
            ]
          }
        }
      ]
        `;
};

export const generateIosVerificationJSON = ({ bundleId }) => {
  return `
  {
    "appID": "${bundleId}",
    "paths": ["/*"]
  }`;
};
