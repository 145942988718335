import React, { useEffect } from "react";
import Label from "../shared/Label";
import lttlFabIcon from '../../assets/lttlFabIcon.png'

const QrCodePreview = ({
  url,
  width,
  isHideLabel = false,
  id,
  downloadQrCode,
  fabIcon = lttlFabIcon
}) => {
  useEffect(() => {
    new window.QrCodeWithLogo({
      canvas: document.getElementById("canvas"),
      content: url,
      width: width ? width : 300,
      download: false,
      image: document.getElementById(id),
      logo: {
        src: fabIcon ?? lttlFabIcon,
        logoSize: 0.2,
      },
    });

    const dwonloadqrcode = new window.QrCodeWithLogo({
      canvas: document.getElementById("canvas"),
      content: url,
      width: 500,
      download: false,
      logo: {
        src: fabIcon ?? lttlFabIcon,
        logoSize: 0.2,
      },
    });
    if (downloadQrCode) downloadQrCode(dwonloadqrcode);

  }, [url]);

  return (
    <div className="flex  flex-col justify-center items-center text-center">
      {!isHideLabel && (
        <div className="">
          <Label label={"Preview"} className={"text-muted"} />
        </div>
      )}

      <div className="qr-wraper" id="qrCode">
        <img src="" alt="Qr Code" id={id} />
      </div>
    </div>
  );
};

export default QrCodePreview;
