import { Button, Group } from '@mantine/core';

export function StepNavigation({ isLastStep, onNext, onPrev, currentStep }) {
    return (
        <Group position="center">
            {
                currentStep !== 0 && (
                    <Button size='xs' variant="subtle" radius={"md"} onClick={onPrev}>Previous</Button>
                )
            }
            <Button radius={"md"} type={isLastStep ? "submit" : "submit"} size='xs' onClick={onNext}>{isLastStep ? 'Finish' : 'Next'}</Button>
        </Group>
    );
}

