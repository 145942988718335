import React from "react";

import { useContext } from "react";
import { Context } from "../context/Context";
import logo from "../assets/lyttlLogo.png";
import { useNavigate } from "react-router-dom";
const BrandLogo = ({ setActiveTab }) => {
  const context = useContext(Context);
  const dashboardConfig = context.dashboardConfig;
  const navigate = useNavigate();
  const onNavigateToDefaultRoute = () => {
    setActiveTab("home");
    navigate(dashboardConfig?.logoNavLink);
  };
  return (
    <div className="px-6">
      {dashboardConfig?.sidebarBrandLogo ? (
        <div
          className="flex  items-center gap-2 cursor-pointer"
          onClick={onNavigateToDefaultRoute}
          tabIndex={0}
        >
          <div>
            <img
              width={40}
              height={40}
              style={{ objectFit: "contain" }}
              src={logo}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = logo;
              }}
              alt=""
            />
          </div>
        </div>
      ) : (
        <div>
          <span className="menu-toggle" onClick={() => { }}>
            <i className="fa-solid fa-bars"></i>
          </span>

          <span className="brand-logo">
            <i className="fas fa-anchor"></i>
          </span>
        </div>
      )}
    </div>
  );
};

export default BrandLogo;
