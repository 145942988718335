import { Text } from "@mantine/core";
import React from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Label from "./shared/Label";


export const GrafanaPanel = ({ url, setUrl, title }) => {


    return (
        <div className=" p-3" >
            <div className=" cursor-pointer flex items-center gap-2 p-3">
                <IoArrowBackCircleOutline className=" dark:text-white" size={"27px"} onClick={() => setUrl(null)} />
                <Label label={title} className={"!text-2xl"} />
            </div>
            <div id="grafana-dashboard" className="w-full p-2">
                <div className="flex justify-end px-4 mb-2">{/* <Dropdown /> */}</div>
                <iframe
                    id="iframe"
                    title="Grafana Dashboard"
                    style={{ width: "100%", height: "100%" }}
                    src={url}
                    sandbox="allow-scripts allow-same-origin"
                />
            </div>
        </div>
    );
};

