export function getShortCode(shortUrl) {
  if (!shortUrl) return "";
  if (!isValidURL(shortUrl)) return "https://hii.com/live";
  const url = new URL(shortUrl);
  const pathname = url.pathname; // "/cD"
  const shortCode = pathname.substring(1); // Removes the leading "/"
  return shortCode;
}

function isValidURL(url) {
  const regex =
    /^(https?|ftp):\/\/(((([a-zA-Z0-9\-.]+)\.[a-zA-Z]{2,})|localhost)(:[0-9]{1,5})?)\/?([^\s]*)$/;
  return regex.test(url);
}
