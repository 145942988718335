import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../context/Context";
import { userService } from "../service/module/userService";
import { toast } from "react-toastify";
const Login = () => {
  const context = useContext(Context);
  const dashboardConfig = context.dashboardConfig;
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/config.dashboard.json"); // Relative path to the JSON file
        const data = await response.json();
        context.onSetDashboardConfig(data);
      } catch (error) {
        console.error("Error fetching dashboard config:", error);
      }
    };
    fetchConfig();
  }, []);
  // State to hold the user input for email and password
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await userService.getUsers(email, password)
      const [user] = data.filter((user) => {
        return user.username === email
      })
      if (user?.username) {
        localStorage.setItem("user", JSON.stringify({ username: email, password: password }));
        localStorage.setItem("lyttl-user", JSON.stringify(user));
        navigate("/dashboard");
      }
      setEmail("");
      setPassword("");
    } catch (error) {
      toast.error("username and password is invalid")
    }
  };
  return (
    <div className=" h-[100vh] w-fll flex items-center justify-center">
      <div className=" w-[450px] py-12  rounded-md bg-[#fff] shadow-md">
        <div className=" px-8">
          <div className="flex items-center flex-col">
            <img
              className="w-[100px]"
              src={dashboardConfig?.sidebarBrandLogo}
              alt=""
            />
            <div className="text-center mt-6 mb-4">
              <p className="text-info text-lg font-bold">Welcome Back</p>
              <p className="text-sm text-info">
                Log in to access your dashboard <br />
                and explore your analytics
              </p>
            </div>
            {/* <h2 className="text-3xl mt-6 text-gradient font-bold mb-4">
              Login
            </h2> */}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block  mb-1 text-[16px] font-medium text-info"
              >
                Username:
              </label>
              <input
                type="email"
                id="email"
                placeholder="Enter your username or email"
                className="mt-1 px-3 py-2 h-[40px] w-full rounded-sm outline focus:outline-blue-500 outline-blue-600 outline-1 focus:outline-2"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="password"
                className="block text-[16px] mb-1 font-medium text-gray-700"
              >
                Password:
              </label>
              <input
                type="password"
                id="password"
                placeholder="Enter your password"
                className="mt-1 px-3 py-2 h-[40px] block w-full rounded-sm focus:outline-blue-500  outline-blue-600 outline-1 focus:outline-2 outline"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2 mt-2">
                <input type="checkbox" id="rememberMe" />
                <label htmlFor="rememberMe" className="text-sm">
                  Remember me
                </label>
              </div>
              <div>
                <p className="text-brand text-sm cursor-pointer">
                  Forgot Password?
                </p>
              </div>
            </div>
            <button
              type="submit"
              className="bg-blue-500 mt-6 text-white px-7 h-[44px] py-2 w-full rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
