export function getSubdomain(domain) {
  if (!domain) return false;
  // Split the domain by dots
  const parts = domain?.split(".");

  // If there are more than two parts, it means there's a subdomain
  if (parts.length > 2) {
    // Join all parts except the last two to get the subdomain
    return parts.slice(0, parts.length - 2).join(".");
  }

  // If there are only two parts, return false as there's no subdomain
  return false;
}
