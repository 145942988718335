import { Skeleton } from '@mantine/core';

const Skel = () => {
    return (
        <div>
            <Skeleton height={30} mt={6} width="100%" radius="sm" />
            <Skeleton height={30} mt={6} width="100%" radius="sm" />
            <Skeleton height={10} mt={6} width="70%" radius="md" />
            <Skeleton height={10} mt={6} width="100%" radius="sm" />
        </div>
    )

}

export function DashboardSkeleton() {

    const d = [1, 2, 3, 56, 6, 8]

    return (
        <div className=' p-4'>
            {
                d.map((d) => (
                    <Skel key={d}></Skel>
                ))
            }
        </div>

    );
}
