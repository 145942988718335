import React from 'react';
import { MultiSelect } from '@mantine/core';
import { useController } from 'react-hook-form';

const MultiSelectInputField = ({ name, control, label, hintText, data, required = false, max = 999 }) => {
    const {
        field: { value, onChange, ref },
        fieldState: { error },
    } = useController({ name, control });

    return (
        <div>
            <MultiSelect
                ref={ref}
                label={label}
                placeholder={hintText}
                data={data}
                value={value || []}
                onChange={onChange}
                error={error?.message}
                maxValues={max}
                searchable
                required={required}

            />
            {error && <p className="text-red-600">{error.message}</p>}
        </div>
    );
};

export default MultiSelectInputField;
