import React from "react";
import { Select, Group } from '@mantine/core';
import { useSearchParams } from "react-router-dom";
import { CgSortAz } from "react-icons/cg";
import { IconCheck } from "@tabler/icons-react";
import { GoSortDesc } from "react-icons/go";
import { BsSortAlphaDown } from "react-icons/bs";
import { BsSortAlphaDownAlt } from "react-icons/bs";
import { RiSortNumberDesc } from "react-icons/ri";
import { RiSortNumberAsc } from "react-icons/ri";
import { GoSortAsc } from "react-icons/go";


const sortingParam = [
  { value: "creationDateAsc", label: "First Created" },
  { value: "creationDateDesc", label: "Last Created" },
  { value: "expirationDateAsc", label: "First Expired" },
  { value: "expirationDateDesc", label: "Last Expired" },
  { value: "titleAsc", label: "A to Z" },
  { value: "titleDesc", label: "Z to A" },
];

const iconProps = {
  stroke: 1.5,
  color: 'currentColor',
  opacity: 0.6,
  size: 18,
};

const icons = {
  creationDateAsc: <GoSortDesc {...iconProps} />,
  creationDateDesc: <GoSortAsc {...iconProps} />,
  expirationDateAsc: <RiSortNumberDesc {...iconProps} />,
  expirationDateDesc: <RiSortNumberAsc {...iconProps} />,
  titleAsc: <BsSortAlphaDown {...iconProps} />,
  titleDesc: <BsSortAlphaDownAlt {...iconProps} />,
};

const renderSelectOption = ({ option, checked }) => (
  <Group flex="1" gap="xs">
    {icons[option.value]}
    {option.label}
    {checked && <IconCheck style={{ marginInlineStart: 'auto' }} {...iconProps} />}
  </Group>
);

export const ShortingLinks = ({ shortingParams = sortingParam }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSortChange = (value) => {
    if (value === null) {
      searchParams.delete('sort')
      setSearchParams(searchParams);
      return true
    }

    searchParams.set("sort", value);
    setSearchParams(searchParams);
  };


  return (
    <Select
      placeholder="Pick one"
      data={shortingParams}
      onChange={handleSortChange}
      value={searchParams.get("sort") || "creationDateAsc"}
      checkIconPosition="right"
      variant="filled"
      size='xs'
      className=" font-semibold text-md"
      leftSection={<CgSortAz size={22} color="#228BE6" />}
      rightSection={<></>}
      comboboxProps={{ withinPortal: false, withArrow: false }}
      renderOption={renderSelectOption}
      styles={{ input: { width: '160px', border: '1px solid #228BE6', fontSize: '15px', color: "#228BE6" } }}
    />
  );
};
