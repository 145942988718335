import { TextInput } from "@mantine/core";
import React, { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { validateRules } from "../../helper/validationRule";

const InputControl = ({
  label,
  name,
  hintText,
  errorMessage,
  type = "text",
  disabled,
  required,
  maxLength = 10000,
  rules = {},
  onChangeEvent = (v) => null,
  size = "sm",
  mt = "md",
  leftSection = "",
  rightSection = ""
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required },
  });

  const [err, setErr] = useState(error?.message)

  const handleInput = (event) => {
    const error = validateRules(event.target.value, rules);
    if (error !== true) {
      setErr(error)
      event.target.setCustomValidity(error);
    } else {
      setErr(null)
      event.target.setCustomValidity('');
    }
  };

  return (
    <div className="">
      <TextInput
        mt={mt}
        required={required}
        disabled={disabled}
        type={type}
        name={name}
        value={value ?? ""}
        label={label}
        placeholder={hintText}
        error={err || errorMessage}
        leftSection={leftSection}
        rightSection={rightSection}
        size={size}
        onChange={(event) => {
          onChange(event.target.value)
          onChangeEvent(event)
        }}
        onBlur={onBlur}
        ref={ref}
        onInput={handleInput}
        maxLength={maxLength}
      />
    </div>
  );
};

export default InputControl;

