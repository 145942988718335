
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Select } from '@mantine/core';

export const SelectControl = ({
    label,
    name,
    data,
    placeholder = 'Select an option',
    defaultValue = '',
    clearable = false,
    required = false,
    size = 'md',
}) => {
    const { control } = useFormContext();
    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
        rules: { required },
    });

    return (
        <Select
            size={size}
            label={label}
            placeholder={placeholder}
            data={data}
            value={value}
            clearable={clearable}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={defaultValue}
            ref={ref}
            error={error ? error.message : null}
        />
    );
};
