import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CiFilter } from "react-icons/ci";
import PrimaryDialog from './shared/PrimaryModal'
import { Button } from '@mantine/core'
import Label from './shared/Label'
import { RxCross1 } from 'react-icons/rx'
import { useSearchParams } from 'react-router-dom';
import MultiSelectInputField from './shared/MultiSelectInput';

function FilterByTag({ tags }) {
    const [open, setOpen] = useState(false)
    const [filter, setFiler] = useState(null)
    const methods = useForm()
    const [searchParams, setSearchParam] = useSearchParams()

    const submit = (e) => {

        setSearchParam(() => {
            return {
                ...Object.fromEntries([...searchParams]),
                tags: e.tags
            }
        })

        if (!e.tags?.length) {
            setOpen(false)
            return true;
        }
        setOpen(false)
        setFiler(e.tags)
    }

    const clear = (e) => {
        e.stopPropagation()
        setFiler(null)
        methods.reset({ tags: [] })
        searchParams.delete("tags")
        setSearchParam(searchParams);

    }

    return (
        <div>
            <Button size="compact-md" onClick={() => setOpen(prv => !prv)} leftSection={<CiFilter />}>
                {
                    filter ? <div className=' flex gap-1 items-center'>
                        {
                            `${filter?.length} filter applied`
                        }
                        <RxCross1 onClick={clear} />
                    </div>
                        : "Add Filter"
                }
            </Button>
            <PrimaryDialog open={open} onClose={() => setOpen(prv => !prv)} title={<Label size={18} label={"Filter By Tags"}></Label>} >
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(submit)}>
                        <div className=' flex flex-col gap-3'>
                            <MultiSelectInputField name={'tags'} data={tags} />
                            <div className=' flex justify-end  gap-2'>
                                <Button leftSection={<RxCross1 />} variant='transparent' size='xs' onClick={clear}>Clear All Filter</Button>
                                <Button type='submit' variant='outline' size='xs'> Apply </Button>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </PrimaryDialog>
        </div>
    )
}

export default FilterByTag