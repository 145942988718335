import { instance } from "..";

export const deepLinkService = {
  async createDeepLink(data) {
    return (await instance.post("/dynamicLink/create.php", data)).data;
  },
  async editDeepLink(data, id, shortCode) {
    return (
      await instance.post(
        `/dynamicLink/update.php?id=${id}&shortCode=${shortCode}`,
        data
      )
    ).data;
  },
  async getDeepLinkByDomain(domain) {
    return (
      await instance.get(
        `/dynamicLink/getDynamicLinkByDomain.php?domain=${domain}`
      )
    ).data;
  },
  async deleteDeepLink(Id) {
    return (await instance.delete(`/dynamicLink/delete.php?id=${Id}`)).data;
  },
};
