export const reverseGenerateResponse = (response) => {
  const input = {
    domain: response.domain,
    url: response.url,
    deep_link_url: response.deep_link_url,
    deep_link_name: response.deep_link_name,
    meta_title: response.meta_title,
    meta_description: response.meta_description,
    meta_image: response.meta_profile_image,
    utm_source: response.utm_source,
    utm_medium: response.utm_medium,
    utm_campaign: response.utm_campaign,
    app_version: response.app_version,
    // expiry_days and short_url are commented out in your original function
    // If they were initially part of input, you would add them here:
    // expiry: response.expiry_days,
    // shortUrl: response.short_url,
  };

  // Determine android_link_behavior based on response values
  if (response.open_android_in_browser) {
    input.android_link_behavior = "android_link_browser";
  } else if (response.android_app_id) {
    input.android_link_behavior = "android_link_mobile";
    input.android_app_id = String(response.android_app_id);
    input.android_custom_url = "android_store_url";

    if (response.android_redirect_url) {
      input.android_custom_url = "android_custom_url";
      input.android_redirect_url = response.android_redirect_url;
    }
  }

  // Determine apple_link_behavior based on response values
  if (response?.open_ios_in_browser) {
    input.apple_link_behavior = "apple_link_browser";
  } else if (response?.ios_app_id) {
    input.apple_link_behavior = "apple_link_mobile";
    input.apple_app_id = String(response?.ios_app_id);
    input.apple_custom_url = "apple_store_url";

    if (response?.ios_redirect_url) {
      input.apple_custom_url = "apple_custom_url";
      input.apple_redirect_url = response?.ios_redirect_url;
    }
  }

  return input;
};
