import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PrimaryDialog from './shared/PrimaryModal';
import EditView from './Links/EditView';
import Button from './shared/Button';
import LinkCard from './Links/LinkCard';
import { linkService } from '../service/module/linkService'; // Adjust the import path
import { toast } from 'react-toastify';

const LinksDetails = ({ linksData, setUrl }) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationKey: ["deleteMutationFN"],
    mutationFn: linkService.deleteLinkById,
  });

  const handleLinkCardEvent = (eventName, item) => {
    switch (eventName) {
      case 'Edit':
        openEditDialog(item);
        break;
      case 'Delete':
        openDeleteConfirmDialog(item);
        break;
      case 'View':
        setUrl(item)
        break;
      default:
        console.warn(`Unhandled event: ${eventName}`);
    }
  };

  const openEditDialog = (item) => {
    setEditData(item);
    setIsEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const openDeleteConfirmDialog = (item) => {
    setDeleteData(item);
    setIsDeleteConfirmDialogOpen(true);
  };

  const closeDeleteConfirmDialog = () => {
    setIsDeleteConfirmDialogOpen(false);
    setDeleteData(null);
  };

  const handleDelete = () => {
    if (deleteData) {
      deleteMutation.mutate(deleteData.id, {
        onSuccess: () => {
          toast.info("Link Deleted Successful")
          queryClient.invalidateQueries({
            queryKey: ["getLinks"]
          });
          closeDeleteConfirmDialog();
        },
        onError: (data) => {
          toast.error(data.message)
        }
      });
    }
  };


  return (
    <div>
      <div className="space-y-4">
        {linksData.length > 0 &&
          linksData.filter((link) => !link.campaign_id && !link.is_qr).map((link) => (
            <LinkCard key={link.id} onClick={handleLinkCardEvent} link={link} />
          ))}
      </div>


      <PrimaryDialog
        open={isEditDialogOpen}
        onClose={closeEditDialog}
        title={'Edit Link'}
      >
        <EditView close={closeEditDialog} editData={editData} />
      </PrimaryDialog>

      <PrimaryDialog
        open={isDeleteConfirmDialogOpen}
        onClose={closeDeleteConfirmDialog}
        title={'Confirmation'}
      >
        <p>Are you sure you want to delete this link?</p>
        <div className="flex justify-end gap-2 mt-8">
          <Button variant="default" onClick={closeDeleteConfirmDialog} label={'No'} />
          <Button color='red' onClick={handleDelete} label={'Yes'} disabled={deleteMutation.isPending} />
        </div>
      </PrimaryDialog>
    </div>
  );
};

export default LinksDetails;
