import React, { useState } from "react";
import { Button } from "@mantine/core";
import PrimaryDialog from "../shared/PrimaryModal";
import Label from "../shared/Label";
import { IoAddCircleOutline } from "react-icons/io5";
import { AddDomain } from "./AddDomain";

const Header = () => {
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(prv => !prv)

    return (
        <div>
            <div className="flex items-center justify-between">
                <Label label={"Domain"} size={22} />

                <div className=" flex items-center gap-2">
                    <Button leftSection={<IoAddCircleOutline size={20} />} radius={"md"} variant="filled" size="sm" onClick={() => setOpen(!open)} >Add Domain</Button>
                </div>

            </div>

            <PrimaryDialog size="lg" open={open} onClose={handleClose} title={<span className=" text-xl font-bold">Create New Domain</span>}>
                <AddDomain handleClose={handleClose} />
            </PrimaryDialog>

        </div>
    );
};

export default Header;
