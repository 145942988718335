export const extractTags = (apiData) => {
  if (!Array.isArray(apiData)) {
    // throw new Error("API data should be an array.");
    return false;
  }

  const tagSet = new Set();

  apiData.forEach((item) => {
    if (item.tags) {
      item.tags.split(",").forEach((tag) => tagSet.add(tag.trim()));
    }
  });

  return Array.from(tagSet);
};
