import React from 'react';
import { useController } from 'react-hook-form';
import { TagsInput } from '@mantine/core';

const TagInput = ({ name, label, placeholder, maxTag = 5 }) => {
    const {
        field: { onChange, onBlur, value },
        fieldState: { error },
    } = useController({
        name,
    });

    return (
        <TagsInput
            label={label}
            placeholder={placeholder}
            value={value ?? []}
            onChange={onChange}
            onBlur={onBlur}
            error={error ? error.message : undefined}
            maxTags={maxTag}
        />
    );
};

export default TagInput;

