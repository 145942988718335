import { useContext, useState } from 'react';
import { Table, Checkbox, Stack, Tooltip, ActionIcon, Text, Badge, Modal, Button } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconTrash } from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { domainService } from '../../service/module/domainService';
import { FaRegEye } from "react-icons/fa";
import { Copy } from '../shared/Copy';
import { RenderStatus } from '../RenderStatus';
import { getSubdomain } from '../../helper/getSubdmoian';
import { Context } from '../../context/Context';
import { formatDate } from '../../helper/formatDate';
import { utcToIST } from '../../helper/utcToIST';



const tableHead = [
    {
        title: "Domain Name",
        id: 1
    },
    {
        title: "Created At",
        id: 2
    },
    {
        title: "Verified At",
        id: 3
    },
    {
        title: "Status",
        id: 4
    },
    {
        title: "Action",
        id: 5
    },
]


export function DomainList() {
    const [selectedRows, setSelectedRows] = useState([]);
    const [showDnsRecord, setShowDnsRecord] = useState(false)
    const [domainVerified, setDomainVerified] = useState()
    const { dashboardConfig } = useContext(Context)

    const queryClient = useQueryClient()

    const { data: domains, isLoading, isError } = useQuery({
        queryKey: ['getDomains'],
        queryFn: () => domainService.getDomains()
    })

    const deleteDomain = useMutation({
        mutationFn: ({ domainName }) => domainService.deleteDomain(domainName),
        mutationKey: ['DeleteDomain']
    })

    const validateDomain = useMutation({
        mutationFn: ({ domainName }) => domainService.validateDomain({ domain_name: domainName }),
        mutationKey: ['validateDomain'],
        onSuccess: (data) => {
            setShowDnsRecord(prv => ({ ...prv, txt_verified: data?.txt_verified ? "success" : 'pending', cname_verified: data?.cname_verified ? "verified" : 'pending' }))
        },
    })

    const selectAll = (e) => {
        if (e.target.checked) {
            setSelectedRows(domains.map((ele) => ele.domain_name))
        } else {
            setSelectedRows([])
        }
    }


    if (isLoading) return <div>Loading</div>;

    if (isError) return <div>Error</div>


    const onClick = (action, value) => {

        switch (action) {
            case "Delete":
                modals.openConfirmModal({
                    title: 'Delete This Link',
                    centered: true,
                    children: (
                        <Text size="sm">
                            Are you sure you want to delete this Dynamic Link? This action is destructive and you will have
                            to contact support to restore your data.
                        </Text>
                    ),
                    labels: { confirm: 'Delete Link', cancel: "No don't delete it" },
                    confirmProps: { color: 'red' },
                    onCancel: () => console.log('Cancel'),
                    onConfirm: () => {
                        deleteDomain.mutate({ domainName: value }, {
                            onSuccess: ({ data }) => {
                                toast.success(data.message)
                                queryClient.invalidateQueries({
                                    queryKey: ['getDomains']
                                })
                            },
                            onError: (err) => {
                                toast.error(err.response.data.message)
                            }
                        });
                    }
                });
                break;
            case "Validate":
                validateDomain.mutate({ domainName: value }, {
                    onSuccess: (data) => {
                        if (data.success) {
                            toast.success(data?.message)
                            // setDomainVerified(data);
                        } else {
                            setDomainVerified(data);
                        }
                        queryClient.invalidateQueries({
                            queryKey: ['getDomains']
                        })
                    },
                    onError: (err) => {
                        setDomainVerified(err.response.data)
                    }
                });
                break;
            case "View":
                setDomainVerified(null)
                setShowDnsRecord(prv => prv ? null : value);
                break;
            default:
                break;

        }

    }

    const rows = domains?.map((element) => (
        <Table.Tr
            key={element.domain_name}
            bg={selectedRows.includes(element.domain_name) ? 'var(--mantine-color-blue-light)' : undefined}
        >
            <Table.Td>
                <Checkbox
                    aria-label="Select row"
                    checked={selectedRows.includes(element.domain_name)}
                    onChange={(event) =>
                        setSelectedRows(
                            event.currentTarget.checked
                                ? [...selectedRows, element.domain_name]
                                : selectedRows.filter((url) => url !== element.domain_name)
                        )
                    }
                />
            </Table.Td>
            <Table.Td>{element.domain_name}</Table.Td>
            <Table.Td>{formatDate(element.created_at)}</Table.Td>
            <Table.Td>{utcToIST(element.verified_at)}</Table.Td>
            <Table.Td>
                <Badge size='sm' color={element.is_verified ? "teal" : "orange"}>
                    {element.verification_status}
                </Badge>
            </Table.Td>
            <Table.Td>
                <div className=' flex gap-2'>
                    {/* <CpButton link={element.short_url}></CpButton>
                     */}
                    {/* <Tooltip label={"Validate Domain"} position='top' withArrow>
                        <ActionIcon variant="default"
                            className="dark:bg-primary dark:hover:bg-softPrimary dark:text-white  "
                            onClick={() => onClick("Validate", element.domain_name)}
                            disabled={element?.is_verified}
                        >

                            <IoRefresh size={18} />
                        </ActionIcon>
                    </Tooltip> */}

                    <Tooltip label={"View DNS Record"} withArrow position="top">
                        <ActionIcon
                            variant="default"
                            className="dark:bg-primary dark:hover:bg-softPrimary dark:text-white  "
                            onClick={() => onClick("View", element)}
                        // disabled={element?.is_verified}
                        >
                            <FaRegEye />
                        </ActionIcon>
                    </Tooltip>

                    <Tooltip label={"Delete"} withArrow position="top">
                        <ActionIcon
                            variant="default"
                            className="dark:bg-primary dark:hover:bg-softPrimary dark:text-white  "
                            onClick={() => onClick("Delete", element.domain_name)}
                        >
                            <IconTrash className="dark:text-white  text-red-600 " />
                        </ActionIcon>
                    </Tooltip>

                </div>
            </Table.Td>
        </Table.Tr>
    ));

    //  getSubdomain()
    const subDomain = getSubdomain(showDnsRecord?.domain_name);


    let data = []

    const isOwnDomain = showDnsRecord?.domain_name?.endsWith('lttl.in');
    if (!isOwnDomain) {
        data.push({ type: 'TXT', host: 'verify-domain-lttl', value: showDnsRecord?.verification_token, status: showDnsRecord?.txt_verified ?? showDnsRecord?.verification_status })
    }

    if (!subDomain) {
        data.unshift(
            { type: 'A', host: "@", value: dashboardConfig?.aRecordAddress, status: showDnsRecord?.cname_verified ?? showDnsRecord?.verification_status },
        )
    } else {
        data.unshift(
            { type: 'CNAME', host: subDomain, value: showDnsRecord?.subdomain, status: showDnsRecord?.cname_verified ?? showDnsRecord?.verification_status },
        )
    }

    return (
        <div>
            <Modal closeOnClickOutside={false} centered opened={showDnsRecord} onClose={setShowDnsRecord} size={"lg"} title={
                <div className=' flex gap-2'>
                    <span className=' text-muted font-semibold'>
                        Validating Domain
                    </span>
                    <span className=' font-bold'>
                        {showDnsRecord?.domain_name}
                    </span>
                </div>
            }>

                <div className=''>
                    {
                        domainVerified?.message && <>
                            <div className="text-red-500 pb-2 px-2">
                                <li> {domainVerified?.message} </li>
                            </div>
                        </>
                    }

                    <div>
                        <Table striped withTableBorder>
                            <Table.Thead>
                                <Table.Tr>
                                    <Table.Th>Type</Table.Th>
                                    <Table.Th>Host</Table.Th>
                                    <Table.Th>Value</Table.Th>
                                    <Table.Th>Status</Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                                {data.map((row, index) => (
                                    <Table.Tr key={index}>
                                        <Table.Td>{row.type}</Table.Td>
                                        <Table.Td>{row.host}</Table.Td>
                                        <Table.Td>
                                            <div className=' flex items-center gap-2'>
                                                {row.value}
                                                <Copy link={row.value} iconSize={12} buttonSize='xs' />
                                            </div>
                                        </Table.Td>
                                        <Table.Td>
                                            <RenderStatus status={row.status}></RenderStatus>
                                        </Table.Td>
                                    </Table.Tr>
                                ))}
                            </Table.Tbody>
                        </Table>
                    </div>

                    <div className=' flex justify-end mt-4'>
                        <Button loading={validateDomain?.isPending} disabled={showDnsRecord?.is_verified} onClick={() => onClick("Validate", showDnsRecord?.domain_name)}> Validate Domain </Button>
                    </div>
                </div>
            </Modal>

            <Stack bg="var(--mantine-color-body)">
                <Table striped withTableBorder highlightOnHover>

                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th> <Checkbox onChange={selectAll}> </Checkbox> </Table.Th>
                            {
                                tableHead.map(({ title, id }) => (
                                    <Table.Th key={id} className=' text-muted text-xs' >{title}</Table.Th>
                                ))
                            }

                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
            </Stack>
        </div>
    );
}