export function cleanUrl(url) {
  // Regular expression to check if URL is a subdomain of lttl.in
  const lttlSubdomainRegex = /^(https?:\/\/)?([a-z0-9.-]+)\.lttl\.in(\/.*)?$/i;

  // Check if the URL matches the subdomain pattern
  if (lttlSubdomainRegex.test(url)) {
    return url; // Return the URL as is if it's a subdomain of lttl.in
  } else {
    // Remove http:// or https:// from the URL
    return url.replace(/^https?:\/\//i, "");
  }
}
