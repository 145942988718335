import React, { useEffect, useState } from "react";
import BrandLogo from "./BrandLogo";
import { useNavigate } from "react-router-dom";
import ProfileDropdown from "./ProfileDropdown";
import DarkMode from "../components/DarkMode";
const Header = ({ setActiveTab }) => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("lyttl-user");
    navigate("/login");
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("lyttl-user"));
    if (userData) {
      setUser(userData);
    }
  }, []);
  return (
    <div className="absolute bg-white  dark:bg-primary   flex top-0 w-full z-50  shadow-lg items-center h-[50px]">
      <header className="flex  items-center  justify-between w-full px-4">
        <div className="flex items-center">
          <BrandLogo setActiveTab={setActiveTab} />
        </div>
        <div className="flex items-center gap-4">
          <DarkMode />
          {user.username ? (
            <ProfileDropdown user={user} onLogout={handleLogout} />
          ) : (
            <span onClick={handleLogout} className="profile-button">
              <i className="fa-solid fa-right-from-bracket cursor-pointer"></i>
            </span>
          )}
        </div>
      </header>
    </div>
  );
};

export default Header;
