export function generateUrl({
  baseUrl,
  campaignName = "ALL",
  medium = "All",
  theme = "light",
  shortCode = "All",
  days = "10",
  orgId = "1",
  labels = "All",
  from = "now-7d",
  to = "now",
  secretKey = "liberin",
  source = "All",
}) {
  const defaultParams = {
    orgId: orgId,
    "var-Days": days,
    "var-CampaignName": campaignName,
    "var-Source": source,
    "var-Medium": medium,
    "var-Labels": labels,
    "var-ShortCode": shortCode,
    from: from,
    to: to,
    theme: theme,
    secretKey: secretKey,
  };

  try {
    const url = new URL(baseUrl);

    for (const key in defaultParams) {
      if (Array.isArray(defaultParams[key])) {
        defaultParams[key].forEach((value) =>
          url.searchParams.append(key, value)
        );
      } else {
        url.searchParams.append(key, defaultParams[key]);
      }
    }

    return url.toString();
  } catch (error) {
    console.error("Invalid URL or parameters:", error);
    return null;
  }
}

// Example usage:
//   const baseUrl = "https://dwardashboard.liberin.in:3000/d/cdgbufkk4z6kge/lyttl-campaign-analytics";
//   const campaignName = "SBI Card Campaign";
//   const medium = ["Email", "SMS"];

//   const updatedUrl = generateUrl(baseUrl, campaignName, medium);
//   console.log(updatedUrl);
// Output: https://dwardashboard.liberin.in:3000/d/cdgbufkk4z6kge/lyttl-campaign-analytics?orgId=1&var-Days=10&var-CampaignName=SBI%20Card%20Campaign&var-Source=All&var-Medium=Email&var-Medium=SMS&var-Labels=All&var-ShortCode=All&from=now-30m&to=now
