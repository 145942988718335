import React from 'react'
import Wrapper from '../components/Wraper'
import Header from '../components/Domain/Header'
import { DomainList } from '../components/Domain/DomainList'

export function AddDomain() {


    return (
        <Wrapper>
            <div className=' flex flex-col gap-6'>
                <Header open={false} setOpen={() => null} handleClose={() => null} />
                <DomainList />
            </div>


        </Wrapper>
    )
}