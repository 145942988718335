import { lttlInstance } from "..";

export const lttlService = {
  async createShortUrl(body) {
    const res = await lttlInstance.post(`/shortUrl/create`, body);
    return res.data;
  },
  async updateShortURL(shortCode, body) {
    const res = await lttlInstance.put(`/shortUrl/${shortCode}`, body);
    return res.data;
  },

  async getLinkDetails(shortCode) {
    return lttlInstance.get(`/shortUrl/${shortCode}`);
  },

  // async createDeepLink(data) {
  //   return lttlInstance.post("/sortURl/create", data);
  // },
};
