export function createSuggestedDomain(domainText, domainName) {
  if (!domainText) return null;
  if (!domainName) return null;

  const domain = domainName.split("//")[1] ?? domainName;

  // Regex to validate the domain format (basic)
  const domainRegex =
    /^(?!:\/\/)([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/;

  if (domainRegex.test(domainText)) {
    return domainText;
  }

  const suggestedDomain = `${domainText}.${domain}`;

  // Check if the suggested domain matches the regex pattern
  if (!domainRegex.test(suggestedDomain)) {
    return null;
  }

  try {
    new URL(`https://${suggestedDomain}`); // Validate the full URL
    return suggestedDomain;
  } catch (e) {
    return null; // Return null if URL is invalid
  }
}
