import React, { useState } from "react";
import InputControl from "../shared/InputControl";
import { useForm, FormProvider } from 'react-hook-form';
import Button from "../shared/Button";
import TagInput from '../shared/TagInput'
import { lttlService } from "../../service/module/lttlService";
import { getSortCodeFromURL } from "../../helper/sortCodeExtractor";
import { getShortCodeDomainFromURL } from "../../helper/extractShortCodeDomain";
import { linkService } from "../../service/module/linkService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { regexURL } from "../../utils/Regex";
import TailEndFiled from "./TailEndField";
import { brandLogoService } from "../../service/module/brandLogo";
import { SelectControl } from "../shared/SelectControl";
import { GoUpload } from "react-icons/go";
import { LoadingOverlay } from "@mantine/core";
import { UploadNewIcon } from "../QrCode/UploadNewIcon";


export const CreatePersonalizeLink = ({ close }) => {
    const methods = useForm();
    const queryClient = useQueryClient();
    const [uploadIcon, setUploadIcon] = useState(false)

    const { data: brandLogos, isLoading } = useQuery({
        queryKey: ["getBrandsLogo"],
        queryFn: () => brandLogoService.getBrandLogo(),
        select: (brandLogos) => brandLogos.map(brandLogo => ({ value: String(brandLogo.id), label: brandLogo.logo_name, default: brandLogo.is_default }))
    })

    const mutation = useMutation({
        mutationKey: ['createLink'],
        mutationFn: async (data) => {
            try {
                const customShortCode = data?.customShortCode
                const response = await lttlService.createShortUrl({
                    url: data.long_url,
                    daysToExpire: data.expirationDays,
                    customShortCode: customShortCode
                });

                const { expiryDate, longUrl, shortUrl } = response;
                const shortCode = getSortCodeFromURL(shortUrl);
                const shortCodeDomain = getShortCodeDomainFromURL(shortUrl);

                const makeRequest = {
                    long_url: longUrl,
                    short_code: shortCode,
                    short_code_domain: shortCodeDomain,
                    title: data.title,
                    description: data.description,
                    tags: data.tags?.toString(),
                    expiry_date: expiryDate,
                    is_qr: 0,
                    brand_logo_id: data?.brand_logo_id,
                    link_type: 'custom'
                };

                return await linkService.createLink(makeRequest);
            } catch (error) {
                throw error;
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["getLinks"]
            });
            close((prv) => !prv); // Adjust this to your actual close function
        },
    });

    const onSubmit = async (data) => {

        mutation.mutate(data, {
            onSuccess: (data) => {
                toast.info(data.message)
            },
            onError: (data) => {
                const err = data?.message ?? data?.response?.data?.errorMessage
                toast.error(err)
            }
        })
    };

    const defaultLogo = brandLogos?.find((logo) => logo.default);

    return (
        <div>
            <FormProvider {...methods}>

                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className=" p-3">
                        <InputControl
                            label="Long URL"
                            name="long_url"
                            hintText="Enter the complete URL, e.g., https://example.com"
                            required={true}
                            maxLength={10000}
                            rules={{ maxLength: [10000, "Long Url cannot be more than 10000 characters!"], validate: [regexURL, "Invalid URL Please enter a valid URL"] }}
                        />
                        <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
                        <div className=" flex gap-2">
                            <TailEndFiled />
                            <div className="flex gap-2 items-end">
                                {!isLoading && (
                                    <SelectControl label={"Choose QR Icon"} size="sm" name={"brand_logo_id"} data={brandLogos} defaultValue={defaultLogo?.value} />
                                )}
                                <Button onClick={() => setUploadIcon(prv => !prv)} label={"Upload New"} variant={"light"} leftSection={<GoUpload />} />
                            </div>
                        </div>

                        <InputControl
                            label="Title"
                            name="title"
                            hintText="Enter Title"
                            maxLength={150}
                            required={true}
                            rules={{ maxLength: [60, "Title cannot be more then 60 character"] }}
                        />
                        <InputControl
                            label="Description"
                            name="description"
                            hintText="Enter description (optional)"
                            maxLength={250}
                            rules={{ maxLength: [240, "Description cannot be more then 240 character"] }}
                        />
                        <div className="mt-3">
                            <TagInput
                                key={"Tags"}
                                name="tags" label="Tags (Press Enter to Add)" placeholder="Enter tag" maxTag={6} />
                        </div>
                        <InputControl
                            label="Expiration Days"
                            name="expirationDays"
                            hintText="Enter number of days: e.g.,30"
                            type="number"
                            isOptional={true}
                            required
                            maxLength={366}
                            rules={{ maxValue: [366, "Expiry cannot be greater than 365 days"], minValue: [1, "The daysToExpire field must be a positive integer."] }}
                        />

                        <div className="flex justify-end gap-2 mt-8">
                            <Button variant="default" onClick={close} label={"Cancel"} />
                            <Button disabled={mutation.isPending} type="submit" label={"Create Link"} />
                        </div>
                    </div>
                </form>
            </FormProvider>
            {
                uploadIcon && <UploadNewIcon open={uploadIcon} setOpen={setUploadIcon}></UploadNewIcon>
            }
        </div>
    )
};

