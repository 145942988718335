import { instance } from "..";

export const brandLogoService = {
  async getBrandLogo() {
    return (await instance.get("/brandIcon/getBrandLogos.php")).data;
  },
  async uploadBrandLogo(formData) {
    return (await instance.post("/brandIcon/uploadIcon.php", formData)).data;
  },
};
