import React, { useContext, useState } from 'react'
import InputControl from '../shared/InputControl'
import { Context } from '../../context/Context';
import { UrlPrefix } from '../shared/UrlPrefix';

function TailEndFiled() {

    const appConfig = useContext(Context);
    const [selectedDomain, setSelectedDomain] = useState(appConfig.dashboardConfig.AppURL);

    return (
        <div className=" flex gap-1 items-center justify-center">

            <div className=" flex-1 mt-4">
                <div>
                    <UrlPrefix label='Select URL' width='180px' currentSelection={selectedDomain} setSelection={setSelectedDomain} />
                </div>
            </div>

            <div>
                <InputControl
                    label="Tail Ends"
                    name="customShortCode"
                    hintText="Enter Custom Tail End"
                    required={true}
                    maxLength={17}
                    rules={{ maxLength: [17, "Tail End must be 2-16 characters long."], validate: [/^[a-zA-Z0-9]{1,17}$/, "Tail End can only contain alphanumeric characters, Spaces are not allowed"], minLength: [1, "Tail End must be 2-16 characters long"] }}
                />
            </div>
        </div>
    )
}

export default React.memo(TailEndFiled)