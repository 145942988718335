import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "@mantine/dropzone/styles.css";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AlertProvider } from "./context/AlertContext";
import ContextProvider from "./context/Context";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ContextProvider>
      <QueryClientProvider client={queryClient}>
        <AlertProvider>
          <App />
        </AlertProvider>
      </QueryClientProvider>
    </ContextProvider>
  </React.StrictMode>
);

reportWebVitals();
