import * as Yup from "yup";

const osLinkBehavior = "android_link_behavior"; // Make sure this matches the field in your form

export const DynamicLinkSchema = Yup.object().shape({
  deep_link_url: Yup.string().required("Deep link URL is required"),
  deep_link_name: Yup.string().required("Deep link name is required"),

  apple_app_id: Yup.string().when("apple_link_behavior", {
    is: (value) => value === `apple_link_mobile`,
    then: (schema) =>
      schema.required(
        "App ID is required when using the mobile app link behavior"
      ),
    otherwise: (schema) => schema.nullable(),
  }),

  android_app_id: Yup.string().when(osLinkBehavior, {
    is: (value) => value === `android_link_mobile`,
    then: (schema) =>
      schema.required(
        "App ID is required when using the mobile app link behavior"
      ),
    otherwise: (schema) => schema.nullable(),
  }),
});
