// AlertContext.js
import React, { createContext, useContext, useState } from "react";
import ReactDOM from "react-dom";

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    isOpen: false,
    title: "",
    message: "",
    positiveLabel: "OK",
    negativeLabel: "Cancel",
    style: {
      positiveButton: {
        backgroundColor: "#228BE6",
        padding: "6px 12px",
        color: "white",
        borderRadius: "6px",
      },
      negativeButton: {
        backgroundColor: "#f2f2f2",
        padding: "6px 12px",
        color: "black",
        borderRadius: "6px",
      },
    },
  });
  const [resolvePromise, setResolvePromise] = useState(null);
  const [rejectPromise, setRejectPromise] = useState(null);

  const showAlert = ({
    title,
    message,
    positiveLabel,
    negativeLabel,
    positiveStyle,
    negativeStyle,
  }) => {
    return new Promise((resolve, reject) => {
      setAlert({
        isOpen: true,
        title,
        message,
        positiveLabel,
        negativeLabel,
        style: {
          negativeButton: {
            ...alert.style.negativeButton,
            ...negativeStyle,
          },
          positiveButton: {
            ...alert.style.positiveButton,
            ...positiveStyle,
          },
        },
      });
      setResolvePromise(() => resolve);
      setRejectPromise(() => reject);
    });
  };

  const hideAlert = () => {
    setAlert((prevAlert) => ({
      ...prevAlert,
      isOpen: false,
    }));
  };

  const handleConfirm = () => {
    if (resolvePromise) {
      resolvePromise(true);
    }
    hideAlert();
  };

  const handleCancel = () => {
    if (rejectPromise) {
      resolvePromise(false);
    }
    hideAlert();
  };

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      {alert.isOpen && (
        <Alert
          title={alert.title}
          message={alert.message}
          positiveLabel={alert.positiveLabel}
          negativeLabel={alert.negativeLabel}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          positiveButtonStyle={alert.style.positiveButton}
          negativeButtonStyle={alert.style.negativeButton}
        />
      )}
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);

const Alert = ({
  title,
  message,
  positiveLabel,
  negativeLabel,
  onConfirm,
  onCancel,
  positiveButtonStyle,
  negativeButtonStyle,
}) => {
  return ReactDOM.createPortal(
    <div className="alert-overlay">
      <div className="alert-dialog">
        <h2>{title}</h2>
        <p>{message}</p>
        <div className="flex justify-end gap-2 mt-8">
          <button style={negativeButtonStyle} onClick={onCancel}>
            {negativeLabel}
          </button>
          <button style={positiveButtonStyle} className="" onClick={onConfirm}>
            {positiveLabel}
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Alert;
