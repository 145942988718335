export const getAppIdentifierById = (app_id, accumulatedData) => {
  // Combine both android and ios arrays
  const allApps = [...accumulatedData.android, ...accumulatedData.ios];

  // Find the app with the given id
  const app = allApps.find((app) => app.value === app_id);

  // Return the app_identifier if the app is found, otherwise return null
  return app ? app.app_identifier : null;
};
export const getStoreIdById = (app_id, accumulatedData) => {
  // Combine both android and ios arrays
  const allApps = [...accumulatedData.ios];

  // Find the app with the given id
  const app = allApps.find((app) => app.value === app_id);

  // Return the app_identifier if the app is found, otherwise return null
  return app ? app.store_id : null;
};
