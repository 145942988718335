const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

export function utcToIST(date) {
  const placeholderPattern = /^0000-00-00 00:00:00$/;

  // Check if the date string matches the pattern. Mence date is empty then return empty date.
  if (placeholderPattern.test(date)) {
    return date;
  }

  const istTime = dayjs(date).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
  return istTime;
}
