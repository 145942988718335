import { Modal } from "@mantine/core";
const PrimaryDialog = ({ open, onClose, children, title, size = "sm" }) => {
  return (
    <>
      <Modal size={size} opened={open} onClose={onClose} title={title} centered>
        {children}
      </Modal>
    </>
  );
};

export default PrimaryDialog;
