import React from 'react';
import { Tooltip, Text } from '@mantine/core';
import { RxQuestionMarkCircled } from 'react-icons/rx';

export function LabelWithTooltip({ label, tooltipText, tooltipPosition = "right" }) {
    return (
        <div className=' inline-flex gap-0.5 items-center'>
            <span>{label}</span>
            <Tooltip transitionProps={{ duration: 400 }} w={360} multiline label={tooltipText} position={tooltipPosition} withArrow>
                <span>
                    <RxQuestionMarkCircled size={12} />
                </span>
            </Tooltip>
        </div>
    );
}


