import React from "react";

const Label = ({ label, size, className }) => {
  return (
    <label
      className={`text-sm block font-semibold dark:text-white text-black/70 ${className} `}
      style={{ fontSize: size }}
    >
      {label}
    </label>
  );
};

export default Label;
