export function filterByTags(data, tags) {
  if (!tags.length) return data;

  if (!data.length) return null;

  const filtered = data.filter((link) => {
    const linkTags = link?.tags?.split(",").map((tag) => tag.trim());
    return tags.some((tag) => linkTags?.includes(tag));
  });
  return filtered;
}
