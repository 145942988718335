import React from 'react'
// import { Description } from './AddDynamicLink'
import { Button, Group, Radio } from '@mantine/core'
import { RadioControl } from '../shared/RadioControl'
import { SelectControl } from '../shared/SelectControl'
import InputControl from '../shared/InputControl'
import { IoAddCircleOutline } from 'react-icons/io5'

export function AppConfiguration({ linkBehavior, selectedApp, customURL, onAddApp, os = 'android', appList = [] }) {



    return (
        <div className=' flex flex-col gap-1'>

            <Radio.Group
                withAsterisk
                value={linkBehavior}
            >
                <Group mt="xs">
                    <RadioControl
                        label="Open deep link in browser"
                        size='xs'
                        name={`${os}_link_behavior`}
                        value={`${os}_link_browser`}
                        defaultChecked
                    />

                    <RadioControl
                        label={`Open deep link in your ${os} app`}
                        size="xs"
                        name={`${os}_link_behavior`}
                        value={`${os}_link_mobile`}
                    />
                </Group>
            </Radio.Group>
            {

                linkBehavior === `${os}_link_mobile` && (
                    <div className=' flex flex-col'>

                        <div className='flex gap-1 pt-3'>
                            <div className=' w-[50%]'>
                                <SelectControl required placeholder='Select an app' size='xs' name={`${os}_app_id`} defaultValue={appList[0]?.value ?? ""} data={appList} />
                            </div>
                            <Button onClick={() => onAddApp(os)} leftSection={<IoAddCircleOutline size={22} />} size='xs' variant='transparent' >Add App</Button>
                        </div>

                        <div>
                            {
                                selectedApp && (
                                    <div className=' py-3'>
                                        <Radio.Group
                                            withAsterisk
                                            label="If your app is not installed, send the user to"
                                            value={customURL}
                                            name={`${os}_custom_url`}
                                        >
                                            <Group mt="xs">
                                                <RadioControl
                                                    label={os === "apple" ? "App Store page for your app" : "Google Play page for your app"}
                                                    size='xs'
                                                    name={`${os}_custom_url`}
                                                    value={`${os}_store_url`}
                                                />

                                                <RadioControl
                                                    label="Custom URL"
                                                    size="xs"
                                                    name={`${os}_custom_url`}
                                                    value={`${os}_custom_url`}
                                                />
                                            </Group>
                                        </Radio.Group>

                                        {
                                            customURL === `${os}_custom_url` && (
                                                <div className=' w-[60%]'>
                                                    <InputControl required hintText={"example: https://example.com"} size='xs' label={"Custom URL"} name={`${os}_redirect_url`}></InputControl>
                                                </div>

                                            )
                                        }
                                    </div>
                                )
                            }

                        </div>
                    </div>

                )

            }

        </div>
    )
}
