import { useContext, useState } from 'react';
import { Stepper, Button, Group, Select, Alert, Table } from '@mantine/core';
import { createSuggestedDomain } from '../../helper/createSuggestedDomain';
import { Context } from '../../context/Context';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { domainService } from '../../service/module/domainService';
import { toast } from 'react-toastify';
import Label from '../shared/Label';
import { Copy } from '../shared/Copy';
import { LabelWithTooltip } from '../shared/LabelWithToolTip';
import { BsFillPatchCheckFill } from "react-icons/bs";
import { RenderStatus } from '../RenderStatus';
import { getSubdomain } from '../../helper/getSubdmoian';

export function AddDomain({ handleClose }) {
    const [active, setActive] = useState(0);
    const dashboard = useContext(Context);
    const [searchValue, setSearchValue] = useState('');
    const [suggestedDomain, setSuggestedDomain] = useState([]);
    const [selectedDomain, setSelectedDomain] = useState(null);
    const isOwnDomain = selectedDomain?.endsWith('lttl.in');
    const [isNotValidated, setIsNotValidated] = useState(true)

    const [customDomain, setCustomDomain] = useState({
        verification_token: "",
        CNAME_record: ""
    })
    const queryClient = useQueryClient()

    const addDomainMutation = useMutation({
        mutationKey: ['addDomain'],
        mutationFn: (data) => domainService.addDomain(data)
    })

    const validateDomain = useMutation({
        mutationFn: (data) => domainService.validateDomain(data),
        mutationKey: ['validateDomain']
    })

    const prevStep = () => {
        setActive((prev) => (prev > 0 ? prev - 1 : 0));
    };

    const nextStep = async () => {
        if (active > 1) return setActive((current) => (current < 2 ? current + 1 : current));

        try {
            if (isOwnDomain) {
                await onSubmit({ domain_name: selectedDomain, is_custom_domain: !isOwnDomain })
            } else {
                setIsNotValidated(true)
                const res = await onSubmit({ domain_name: selectedDomain, is_custom_domain: true });
                setCustomDomain(res);
                // console.log("res", res)
                if (res.success) {
                    setActive((current) => (current < 2 ? current + 1 : current));
                }
            }
        } catch (error) {
            toast.error('failed to register domain')
        }
    };

    if (active === 2) {
        setTimeout(() => {
            setActive(3)
        }, 500)
    }

    const searchDomain = (inputText) => {
        if (!inputText.length) {
            setIsNotValidated(true);
            setSearchValue('');
            setSuggestedDomain([]);
            return;
        }
        setSearchValue(inputText);
        if (suggestedDomain[0] === inputText) {
            return;
        }
        const suggested = createSuggestedDomain(inputText, dashboard?.dashboardConfig?.AppURL);
        setSuggestedDomain(suggested ? [suggested] : []);
    };

    const onSubmit = async (data) => {
        try {
            const customDomain = await addDomainMutation.mutateAsync(data, {
                onSuccess: (data) => {
                    toast.success(data.message)
                    if (!data?.verification_token)
                        setActive(3); // Go to the last page because it's own domain and it's already verified. 
                    queryClient.invalidateQueries({
                        queryKey: ['getDomains']
                    })
                },
            });
            return customDomain
        } catch ({ response }) { // It's give error response. 
            toast.error(response.data.message)
            return response.data
        }
    }

    const selectCap = (e) => {
        setIsNotValidated(false);
        setSelectedDomain(e);
    };

    const validateLink = () => {
        validateDomain.mutate({ domain_name: selectedDomain }, {
            onSuccess: (data) => {
                if (data.success) {
                    alert("data success")
                }
            },
            onError: (data) => {
                console.log("e", data)
            }
        })
    }

    const subDomain = getSubdomain(customDomain?.domain_name);


    let data = [{ type: 'TXT', host: 'verify-domain-lttl', value: customDomain?.verification_token, status: customDomain?.status }]
    if (!subDomain) {
        data.unshift(
            { type: 'A', host: "@", value: dashboard.dashboardConfig?.aRecordAddress, status: customDomain?.status },
        )
    } else {
        data.unshift(
            { type: 'CNAME', host: subDomain, value: customDomain?.subdomain, status: customDomain?.status },
        )
    }

    return (
        <div className=' px-3 '>
            <Stepper size="xs" active={active} onStepClick={setActive}>
                <Stepper.Step label="Create">
                    <div className='flex flex-col gap-3 rounded-sm py-5 px-3 '>
                        <Label label={"Brand your links completely with a URL prefix made up of a custom dynamic links domain (e.g. yourdomain.com) and an optional path (e.g. yourdomain.com/mypath). Customized domains look more professional and contextual, resulting in more clicks on links."}></Label>
                        <Select
                            label={<LabelWithTooltip tooltipText={"Custom dynamic links domain (e.g. yourdomain.com) and an optional path (e.g. yourdomain.com/mypath)"} label={"Domain"}></LabelWithTooltip>}
                            searchable
                            searchValue={searchValue}
                            onSearchChange={searchDomain}
                            data={suggestedDomain}
                            onChange={selectCap}
                            allowDeselect={false}
                            rightSection={<></>}
                            radius={"md"}
                            placeholder='Enter a domain name'
                        />
                    </div>
                </Stepper.Step>
                <Stepper.Step label="Configure">
                    <div className='py-5 px-3 rounded-sm'>
                        <Label className={"inline"} label={"To validate your domain ownership copy the hostname records below and enter them with your domain provider."}></Label>
                        <a className='pl-1 font-semibold text-sm text-blue-600' href='/learn'>Learn More</a>

                        <div className=' py-2'>
                            <Table striped withTableBorder>
                                <Table.Thead>
                                    <Table.Tr>
                                        <Table.Th>Type</Table.Th>
                                        <Table.Th>Host</Table.Th>
                                        <Table.Th>Value</Table.Th>
                                        <Table.Th>Status</Table.Th>
                                    </Table.Tr>
                                </Table.Thead>
                                <Table.Tbody>
                                    {data.map((row, index) => (
                                        <Table.Tr key={index}>
                                            <Table.Td>{row.type}</Table.Td>
                                            <Table.Td>{row.host}

                                            </Table.Td>
                                            <Table.Td>
                                                <div className=' flex items-center gap-2'>
                                                    {row.value}
                                                    <Copy link={row.value} iconSize={12} buttonSize='xs' />
                                                </div>
                                            </Table.Td>
                                            <Table.Td>
                                                <RenderStatus status={row.status}></RenderStatus>
                                            </Table.Td>
                                        </Table.Tr>
                                    ))}
                                </Table.Tbody>
                            </Table>
                        </div>

                    </div>

                </Stepper.Step>
                <Stepper.Step label="Finish">
                    <Alert variant="light" color="blue" title="Domain Registration">
                        Registration successful, Now you Create new deep link using this registered domain.
                    </Alert>
                </Stepper.Step>

                <Stepper.Completed >
                    <Alert variant="light" color="blue" title="Domain Registration">
                        Registration successful, Now you Create new deep link using this registered domain.
                    </Alert>
                </Stepper.Completed>

            </Stepper>

            <Group justify="space-between" mt="md">
                <div>
                    {
                        active === 1 && (
                            <Button onClick={validateLink} variant='transparent' leftSection={<BsFillPatchCheckFill />}> Validate Domain </Button>
                        )
                    }
                </div>

                <div className=' flex gap-3'>
                    {
                        active === 3 ? (
                            <Button onClick={handleClose}>Finish</Button>
                        ) : (
                            <>
                                <Button disabled={active === 0} variant="default" onClick={prevStep}>Back</Button>
                                <Button disabled={addDomainMutation.isPending || isNotValidated} onClick={nextStep}>Next step</Button>
                            </>
                        )
                    }
                </div>
            </Group>
        </div >
    );
}
