import { CopyButton, ActionIcon, Tooltip, rem } from "@mantine/core";
import { IconCopy, IconCheck } from "@tabler/icons-react";

export const Copy = ({ link, disabled = false, iconSize = 18, buttonSize = "md" }) => {
    return (
        <CopyButton value={link} timeout={2000}>
            {({ copied, copy }) => (
                <Tooltip label={copied ? "Copied" : "Copy Short URL"} withArrow position="top">
                    <ActionIcon
                        className="dark:bg-primary dark:hover:bg-softPrimary dark:hover:text-white  text-white"
                        color={copied ? "teal" : "gray"}
                        variant="default"
                        onClick={copy}
                        disabled={disabled}
                        size={buttonSize}
                    >
                        {copied ? (
                            <IconCheck
                                className="dark:text-white text-black"
                                style={{ width: rem(iconSize) }}
                            />
                        ) : (
                            <IconCopy
                                className="dark:text-white text-black"
                                style={{ width: rem(iconSize) }}
                            />
                        )}
                    </ActionIcon>
                </Tooltip>
            )}
        </CopyButton>
    );
};


