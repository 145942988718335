import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../context/Context";
const GrafanaDashboard = () => {
  const [iframeURl, setIframeURL] = useState("");
  const context = useContext(Context);
  const [headerTitle, setHeaderTitle] = useState();
  const dashboardsConfig = context.dashboardConfig;
  const { theme } = useContext(Context);
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (dashboardsConfig) {
      dashboardsConfig?.dashboards[0].menuItems.forEach((menu) => {
        menu.subMenuItems.forEach((submenu) => {
          if (submenu.path === pathname) {
            setIframeURL(submenu.iframeURL + `&theme=${theme}`);
            setHeaderTitle(submenu?.headerTitle);
          }
        });
      });
    }
  }, [iframeURl, pathname, dashboardsConfig, theme]);

  return (
    <div id="grafana-dashboard" className="w-full px-2">
      <div className="flex justify-end px-4 mb-2">{/* <Dropdown /> */}</div>
      <p className=" dark:text-white px-1 py-2 my-2 font-bold text-xl text-textColor">
        {headerTitle}
      </p>
      <iframe
        id="iframe"
        title="Grafana Dashboard"
        style={{ width: "100%", height: "100%" }}
        src={iframeURl}
        sandbox="allow-scripts allow-same-origin"
      />
    </div>
  );
};

export default GrafanaDashboard;
