import { setupRequestInterceptor } from "./intercepter/interceptor";

import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;
export const instance = axios.create({
  baseURL: baseURL,
  timeout: 6000,
});

setupRequestInterceptor(instance);

const lttlURL = process.env.REACT_APP_LTTL_URL;
export const lttlInstance = axios.create({
  baseURL: lttlURL,
  timeout: 6000,
});
