import React, { useState } from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import { Button, Timeline } from '@mantine/core'
import Wrapper from '../../Wraper'
import { Step } from '../Step'
import InputControl from '../../shared/InputControl'
import { LabelWithTooltip } from '../../shared/LabelWithToolTip'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { appRegistrationService } from '../../../service/module/appRegistrationService'
import { toast } from 'react-toastify'
import { generateAndroidVerificationJSON, generateIosVerificationJSON } from '../../../helper/generateJSON'

export function AddApplication({ os, closeModal, selectedDomain }) {
    const [active, setActive] = useState(0)
    const methods = useForm();

    const queryClient = useQueryClient()

    const registerApplicationMutation = useMutation({
        mutationKey: ['registerApp'],
        mutationFn: (data) => appRegistrationService.registerApplication(data)
    })

    const createAssociationMutation = useMutation({
        mutationKey: ["createAssociation"],
        mutationFn: (data) => appRegistrationService.createAssociation(data)
    })

    const onSubmit = (data) => {
        const osName = os === "apple" ? "IOS" : "Android"; // take json based on Device.

        registerApplicationMutation.mutate({ ...data, os: osName }, {
            onSuccess: (data) => {
                toast.success(data.message)
                nextStep()
                queryClient.invalidateQueries([{
                    queryKey: ['getApp']
                }])
            },
            onError: ({ response }) => {
                toast.error(response.data.message)
            }
        })

        let json
        if (os === "android") {
            json = generateAndroidVerificationJSON({ package_name: data.app_identifier, sh: data.sha });
        } else {
            json = generateIosVerificationJSON({ bundleId: data.app_identifier });
        }

        createAssociationMutation.mutate({
            domain: selectedDomain,
            json,
            os: osName,
        }, {
            onSuccess: (data) => {
                toast.success(data.message)
            },
            onError: ({ response }) => {
                toast.error(response.data.message)
            }
        });


    };


    const prevStep = () => {
        setActive((prev) => {
            return Math.max(prev - 1, 0) // Ensure active doesn't go below 0
        })
    };

    const nextStep = () => {
        setActive((prev) => {
            return Math.min(prev + 1, 2);
        })
    };

    const stepClick = (stepIndex) => {
        if (stepIndex === active || stepIndex === 0) {
            return
        }
        if (stepClick > active)
            setActive(active + 1)
        else
            setActive(active - 1)
    }



    const steps = [
        {
            title: "Register App",
            content: (
                <div className='w-[50%] px-4'>
                    <InputControl hintText={"com.osunio.app"} required name={"app_identifier"} label={
                        <LabelWithTooltip
                            label={os === 'android' ? "Android package name" : "Apple Bundle Id"}
                            tooltipText="This is the unique identifier for your app"
                        />
                    }></InputControl>

                    <InputControl required hintText={"your app"} name={"app_name"} label={<LabelWithTooltip label="App Nickname" tooltipText={"If specified, the app nickname will be used throughout the Firebase console to represent this app. Nicknames aren't visible to users."} />}></InputControl>
                    {
                        os === 'android' ? (
                            <InputControl hintText={"00:00:00:00:00:00:00:00:00"} name={"sha"} label={<LabelWithTooltip label="SHA" tooltipText={"using keytool to get the SHA-1 hash of your signing certificate."} />}></InputControl>
                        ) : (
                            <InputControl hintText={"123456"} name={"store_id"} label={<LabelWithTooltip label={"Store Id"} tooltipText={"You can find your App Store ID in your app's URL. In the example below, 123456789 is the App Store ID. https://itunes.apple.com/us/app/yourapp/id123456789  "} />}></InputControl>
                        )
                    }
                </div>
            ),
            navigator: (
                <div className=' px-4'>
                    <Button size='xs' type='submit' >Register</Button>
                </div>
            )

        },
        {
            title: "Next Step",
            content: (
                <div>
                    <p>You're all set!</p>
                    <p>Make sure to check out the documentation to learn how to get started with Dynamic Link, that you want to use in your app.</p>
                </div>
            ),
            navigator: (
                <div>
                    <Button onClick={() => closeModal(prv => !prv)} size='xs'>Continue To console</Button>
                </div>
            )
        }
    ]


    return (
        <Wrapper>
            <div className=' px-5 py-6 flex'>
                <div className=' px-3 py-1 flex-1'>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Timeline size='sm' active={active} onStepClick={stepClick} orientation="vertical">
                                {steps.map((step, index) => (
                                    <Timeline.Item bullet={<p className=' text-xs font-semibold'>{index + 1}</p>} key={index} title={step.title} description={step.description}>
                                        {active === index && (
                                            <Step
                                                content={step.content}
                                                onNext={nextStep}
                                                onPrev={prevStep}
                                                isLastStep={active === steps.length - 1}
                                                currentStep={active}
                                                stepNavigator={step?.navigator}
                                            />
                                        )}
                                    </Timeline.Item>
                                ))}
                            </Timeline>

                        </form>
                    </FormProvider>

                </div>
                <div className=' flex-1'>

                </div>
            </div>
        </Wrapper>
    )
}
