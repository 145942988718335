export function calculateDaysDifference(expiryDate) {
  // Parse the expiry date
  const expiry = new Date(expiryDate);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in time
  const timeDifference = expiry - currentDate;

  // Convert the time difference from milliseconds to days
  let dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  if (dayDifference < 0) dayDifference = 0;

  return dayDifference;
}
