export function filterByDate(data, filterDates) {
  if (filterDates[0] && filterDates[1]) {
    const startDate = filterDates[0];
    const endDate = filterDates[1];

    const filtered = data?.filter((item) => {
      const createdAt = new Date(item.created_at);
      return createdAt >= new Date(startDate) && createdAt <= new Date(endDate);
    });
    return filtered;
  } else {
    return data;
  }
}
