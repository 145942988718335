import React, { useContext, useState } from "react";
import LinksDetails from "../components/LinksDetails";
import Wrapper from "../components/Wraper";
import { useQuery } from "@tanstack/react-query";
import { linkService } from "../service/module/linkService";
import { GrafanaPanel } from "../components/UnlabledGrafana";
import { Context } from "../context/Context";
import { DashboardSkeleton } from "../components/Skeleton";
import FilterByDate from "../components/FilterByDate";
import FilterByTag from "../components/FilterByTag";
import { extractTags } from "../helper/extractTags";
import { useSearchParams } from "react-router-dom";
import { filterByTags } from "../helper/filterByTags";
import { filterByDate } from "../helper/filterByDate";
import { generateUrl } from "../helper/generateURL";
import Label from "../components/shared/Label";
import PrimaryDialog from "../components/shared/PrimaryModal";
import { CreatePersonalizeLink } from "../components/PersonalizeLinks/PersonalizeLinks";
import { Button } from "@mantine/core";
import { ShortingLinks } from "../components/SortingLinks";
import { sortData } from "../helper/sortData";
import QrCodeCard from "../components/QrCode/QrCodeCard";

export const PersonalizeLinks = () => {
    const [open, setOpen] = useState(false);
    const { theme } = useContext(Context)
    const [url, setUrl] = useState()
    const [searchParams] = useSearchParams()

    const { data, isLoading, isError } = useQuery({
        queryKey: ["getLinks"], queryFn: linkService.getLinks,
        select: (data) => data.filter(link => link.link_type === "custom")
    })

    const tags = searchParams.getAll('tags');
    const dateTo = searchParams.get('filterTo');
    const dateFrom = searchParams.get('filterFrom');
    const sortBy = searchParams.get('sort');

    let filteredData = filterByTags(data, tags);
    filteredData = filterByDate(filteredData, [dateTo, dateFrom]);
    filteredData = sortData(filteredData, sortBy)

    const handleClose = () => {
        setOpen(!open);
    };

    if (isLoading) {
        return <DashboardSkeleton />
    }

    if (isError) {
        <div className=" text-red-800">
            Something went wrong
        </div>
    }

    const baseUnlabeledUrl = process.env.REACT_APP_DASHBOARD_UNLABELED_URL;

    const urlMap = generateUrl({ baseUrl: baseUnlabeledUrl, shortCode: url?.short_code, theme });

    const emptyView = (
        <div className="text-center gap-3 flex items-center justify-center flex-col">
            <img
                width={100}
                style={{ objectFit: "contain" }}
                src="https://lyttl.in/wp-content/uploads/2024/02/Group-427320437.png"
                alt="empty-view"
            />
            <p className=" text-2xl font-bold mt-4">
                Unlock possibilities with just one click!
            </p>
            <Button onClick={() => setOpen(!open)}>Personalize a Tail End</Button>
        </div>
    );
    return (
        <div>
            {
                url ?
                    (<GrafanaPanel title={url?.title} url={urlMap} setUrl={setUrl}>
                    </GrafanaPanel>) : (
                        <Wrapper>
                            <div>
                                <div className=" flex justify-between items-center px-4">
                                    <div className="flex gap-2">
                                        <div className="flex pl-2 gap-4 items-center">
                                            <Label label={"Personalized Tail Ends"} size={22} />
                                            <Button color="#5F63F2" variant="primary" size="sm" onClick={() => setOpen(!open)} >Create Link</Button>
                                        </div>

                                        <PrimaryDialog size="xl" open={open} onClose={handleClose} title={<span className=" text-xl font-bold">Create a Personalized Tail End</span>}>
                                            <CreatePersonalizeLink close={handleClose} />
                                        </PrimaryDialog>

                                    </div>
                                    <div className=" flex gap-2">
                                        <FilterByTag tags={extractTags(data)} />
                                        <FilterByDate />
                                        <ShortingLinks />
                                    </div>
                                </div>
                                <div className=" py-2">
                                    {filteredData?.length > 0 ? (
                                        filteredData.map(link => (
                                            <QrCodeCard key={link.id} item={link} setUrl={setUrl} />
                                        ))
                                    ) : (
                                        emptyView
                                    )}
                                </div>
                            </div>
                        </Wrapper>
                    )
            }
        </div>

    );
};

