import { ActionIcon, Badge, Button, Tooltip } from "@mantine/core";
import Label from "../shared/Label";
import CpButton from "./CpButton";
import { IoIosLink } from "react-icons/io";

import {
  IconBrandGoogleAnalytics,
  IconCalendarEvent,
  IconEdit,
  IconTagStarred,
  IconTrash,
} from "@tabler/icons-react";

import { FaRegCalendarTimes } from "react-icons/fa";
import { formatDate } from "../../helper/formatDate";
import { isExpired } from "../../helper/isExpired";

const LinkCard = ({ onClick, link }) => {
  const { title, short_code, short_code_domain, tags, created_at, long_url, expiry_date } = link;

  const expired = isExpired(expiry_date)

  return (
    <div className="p-4 px-8 mx-4 bg-white mt-6 dark:bg-softPrimary shadow-lg flex-col rounded-md">
      <div className="flex items-center justify-between">
        <Label label={title} className={"!text-xl overflow-hidden "} />
        <div className="flex gap-2 items-center">
          <CpButton disabled={expired} link={`https://${short_code_domain}/${short_code}`} />
          <Tooltip label={"Edit"} withArrow position="top">
            <ActionIcon
              className="dark:bg-primary dark:hover:bg-softPrimary dark:hover:text-white "
              variant="default"
              onClick={() => onClick("Edit", link)}
              disabled={expired}
            >
              <IconEdit className="dark:text-white text-black" />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={"Delete"} withArrow position="top">
            <ActionIcon
              variant="default"
              className="dark:bg-primary dark:hover:bg-softPrimary dark:text-white  "
              onClick={() => onClick("Delete", link)}
            >
              <IconTrash className="dark:text-white  text-red-600 " />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={"View Analytics"} withArrow position="top">
            <Button
              variant="outline"
              size="xs"
              className="dark:bg-primary flex dark:hover:bg-softPrimary dark:text-white bg-softPrimary "
              onClick={() => onClick("View", { short_code, title })}
            >
              <IconBrandGoogleAnalytics size={18} className="dark:text-white me-2 " />
              View Analytics
            </Button>
          </Tooltip>
        </div>
      </div>

      <div className="mt-4 flex pl-2  justify-center items-center align-start w-full gap-2 overflow-hidden">

        <IoIosLink className=" dark:text-white" size={'30px'} />

        <div className="ms-4 w-full overflow-hidden">
          <div className="text-brand text-md font-semibold flex gap-2 items-center">
            <span className="  hover:underline inline-block cursor-pointer"> {`https://${short_code_domain}/${short_code}`}</span>
            {expired &&
              <Badge color="rgba(196, 12, 12, 1)" size="xs">Expired</Badge>
            }
          </div>
          <p className=" text-black/60 dark:text-brand hover:underline">
            <span className="  hover:underline inline-block cursor-pointer">{long_url}</span>
          </p>
        </div>
      </div>
      <hr className="mt-4" />
      <div className="flex gap-4 items-center mt-2">
        <div className="flex gap-1 items-center">
          <IconCalendarEvent width={18} className="dark:text-white text-black/60" />
          <p className="text-sm text-black/60 dark:text-white">{formatDate(created_at)}</p>
        </div>
        <div className="flex gap-1 items-center">
          <FaRegCalendarTimes width={16} className="dark:text-white text-black/60 " />
          <p className="text-sm text-black/60 dark:text-white">{formatDate(expiry_date)}</p>
        </div>
        <div className="flex gap-1 items-center">
          <IconTagStarred width={18} className="dark:text-white text-black/60" />
          <div className="flex gap-2 items-center">
            {tags?.split(',').length > 0 ? (
              tags?.split(',').map((tag, index) => (
                <span key={index} className=" dark:text-white text-sm text-info dark:bg-slate-800 bg-slate-100 px-3 py-1 rounded-3xl">{tag}</span>
              ))
            ) : (
              <span className="text-muted">No tags</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkCard;
