import React from "react";
import { Button } from "@mantine/core";
import PrimaryDialog from "../shared/PrimaryModal";
import Label from "../shared/Label";
import { IoAddCircleOutline } from "react-icons/io5";
import { AddDomain } from "../Domain/AddDomain";

const Header = ({ open, setOpen, handleClose, setOpenDynamicLink }) => {
    return (
        <div>
            <div className="flex items-center justify-between">
                <Label label={"Dynamic Link"} size={22} />

                <div className=" flex items-center gap-2">
                    <Button leftSection={<IoAddCircleOutline size={20} />} radius={"md"} variant="outline" size="sm" onClick={() => setOpen(!open)} >URL Prefix</Button>
                    <Button leftSection={<IoAddCircleOutline size={20} />} radius={"md"} variant="primary" size="sm" onClick={() => setOpenDynamicLink(prv => !prv)} >Dynamic Link</Button>
                </div>

            </div>

            <PrimaryDialog size="lg" open={open} onClose={handleClose} title={<span className=" text-xl font-bold">Create New Domain</span>}>
                <AddDomain handleClose={handleClose} />
            </PrimaryDialog>

        </div>
    );
};

export default Header;
