import { useEffect, useState } from 'react';
import { Alert, Button, Group, Modal, Timeline } from '@mantine/core';
import InputControl from '../shared/InputControl';
import { FormProvider, useForm } from 'react-hook-form';
import { Step } from './Step';
import { AppConfiguration } from './AppConfiguration';
import { AddApplication } from './AddApplication/AddApplication';
import { capitalize } from '../../helper/capitalize';
import { generateResponse } from '../../helper/generateResponse';
import { generateDeepLink } from '../../helper/generateDeepLink';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deepLinkService } from '../../service/module/deepLinkService';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup'
import { DynamicLinkSchema } from './validation/dynamicLinkValidation';
import { regexURL } from '../../utils/Regex';
import Label from '../shared/Label';
import CpButton from '../Links/CpButton';
import { reverseGenerateResponse } from '../../helper/resetField';
import { getShortCode } from '../../helper/getShortCode';

export const Description = ({ children }) => (
    <span className='text-xs text-muted p-0'>
        {children}
    </span>
)

export function EditDynamicLink({ selectedDomain, apps, closeModal, data }) {

    const [active, setActive] = useState(0);
    const [isCompleted, setIsComplied] = useState(false)
    const [selectedOs, setSelectedOs] = useState('apple')
    const [openAddApp, setOpenAddApp] = useState(null)
    const [shortURL, setShortURL] = useState(null)
    const queryClient = useQueryClient()
    const LinkId = data?.id
    const shortCode = getShortCode(data?.short_url)


    const methods = useForm({
        defaultValues: {
            apple_link_behavior: 'apple_link_browser',
            apple_custom_url: 'apple_store_url',
            android_link_behavior: 'android_link_browser',
            android_custom_url: 'android_store_url',
            app_version: "1.0.1"
        },
        resolver: yupResolver(DynamicLinkSchema)
    })

    useEffect(() => {
        if (data && methods) {
            methods.reset(reverseGenerateResponse(data))
        }
    }, [data, methods])

    const crateDeepLinkLttl = useMutation({
        mutationKey: ['createDeepLink'],
        mutationFn: (data) => deepLinkService.editDeepLink(data, LinkId, shortCode)
    })

    const prevStep = () => {
        setActive((prev) => {
            if (prev === 2) {
                setSelectedOs("apple")
                setIsComplied(false)
            }
            return Math.max(prev - 1, 0)
        })
    };

    const nextStep = () => {
        setActive((prev) => {
            if (prev === 1) {
                setSelectedOs("android")
            }
            if (prev === 2) {
                setIsComplied(true)
            }
            return Math.min(prev + 1, 4);
        })
    };

    const stepClick = (stepIndex) => {
        if (stepIndex === active || stepIndex === 0) {
            return null
        }
        if (stepClick > active)
            setActive(active + 1)
        else
            setActive(active - 1)
    }

    const onSubmit = async (data) => {
        const url = generateDeepLink(data, apps);

        if (url && selectedDomain) {

            const res = generateResponse({ ...data, domain: selectedDomain, url });

            crateDeepLinkLttl.mutate(res, {
                onSuccess: (data) => {
                    queryClient.invalidateQueries({
                        queryKey: ['getDomain', selectedDomain]
                    })
                    toast.success(data.message)
                    setShortURL(data.short_url)
                    // closeModal()
                    nextStep();

                },
                onError: (data) => {
                    toast.error("Failed To update Record")
                }
            })
        }
    };

    const linkBehavior = methods.watch(`${selectedOs}_link_behavior`)
    const selectedApp = methods.watch(`${selectedOs}_app_id`)
    const customURL = methods.watch(`${selectedOs}_custom_url`)


    const steps = [
        {
            title: 'Set up your dynamic link',
            content: (
                <div className='flex gap-2 flex-col px-1 py-1'>
                    <Description>A dynamic link is a deep link into your app that works whether or not your app is installed. On desktop, it will go to the deep link URL.</Description>

                    <div className='flex gap-2'>
                        <div className='flex-auto'>
                            <InputControl rules={{ validate: [regexURL, "Invalid URL Please enter a valid URL"] }} required hintText={"Example: https:yourdomain.com"} mt="xs" size='xs' label={"Deep Link URL"} name={"deep_link_url"} />
                        </div>
                        <div className='flex-auto'>
                            <InputControl rules={{ maxLength: [60, "Title cannot more then 60 character"] }} required hintText={"Example: Session Promo"} mt="xs" size='xs' label={"Deep Link Name"} name={"deep_link_name"} />
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Define link behavior for Apple',
            content: (
                <div className=' my-3'>
                    <AppConfiguration linkBehavior={linkBehavior} selectedApp={selectedApp} customURL={customURL} onAddApp={setOpenAddApp} os={"apple"} appList={apps.ios} />
                </div>

            ),
        },
        {
            title: 'Define link behavior for Android',
            content: (
                <div className=' my-3'>
                    <AppConfiguration linkBehavior={linkBehavior} selectedApp={selectedApp} onAddApp={setOpenAddApp} customURL={customURL} os={"android"} appList={apps.android} />
                </div>
            ),
        },
        {
            title: "Campaign Tracking, Social tags, Advance Options",
            content: (
                <div className=' px-3 py-3 flex gap-4 flex-col'>
                    <div>
                        <li>
                            <Label className={" text-[16.2px] italic inline text-[var(--mantine-color-blue-filled-hover)]"} label={"Add social meta tags for better sharing"}></Label>
                        </li>
                        <div className=' my-2'>
                            <div className=' flex gap-2'>
                                <div className=' flex-auto'>
                                    <InputControl hintText={"Example: Holiday Promotion"} mt="" label={"Preview title"} name={"meta_title"}></InputControl>
                                </div>
                                <div className=' flex-auto'>
                                    <InputControl hintText={"Example: Get 20% discount when you spend 999 or more"} mt="" label={"Preview Description"} name={"meta_description"}></InputControl>
                                </div>
                            </div>
                            <div>
                                <InputControl hintText={"Example: https://domainname.com/preview-image"} mt="" label={"Preview Image URL"} name={"meta_image"}></InputControl>
                            </div>
                        </div>
                    </div>

                    <div>
                        <li>
                            <Label className={" text-[16.2px] italic inline text-[var(--mantine-color-blue-filled-hover)]"} label={"Track Campaign with UTM parameters"}></Label>
                        </li>
                        <div className=' my-2'>
                            <div className=' flex gap-2'>
                                <div className=' flex-auto'>
                                    <InputControl hintText={"Example: Youtube Ads"} mt="" label={"Campaign Source (utm_source)"} name={"utm_source"}></InputControl>
                                </div>
                                <div className=' flex-auto'>
                                    <InputControl hintText={"Example: CPC,Ads"} mt="" label={"Campaign Medium (utm_medium)"} name={"utm_medium"}></InputControl>
                                </div>
                            </div>
                            <div>
                                <InputControl hintText={"Example: Winter Sale"} mt="" label={"Campaign Name (utm_campaign)"} name={"utm_campaign"}></InputControl>
                            </div>
                        </div>
                    </div>

                </div>
            ),
            navigator: (
                <div className=' mx-3'>
                    <Button size='xs' variant="subtle" radius={"md"} onClick={prevStep}>Previous</Button>
                </div>
            )
        },
        {
            title: "Finish",
            content: (
                <div className=' py-3'>
                    <Alert title="Deep Link Created Successful">
                        <div className=' flex items-center justify-between'>
                            {shortURL && <div>
                                <Label label={`Deep Link: ${shortURL}`}></Label>
                            </div>}
                            <CpButton link={shortURL}></CpButton>
                        </div>
                    </Alert>

                </div>
            ),
            navigator: (
                <></>
            )
        }
    ];

    const errorArray = Object.values(methods.formState.errors).map(error => error.message);

    return (
        <div className=' px-3 py-1'>
            <div className='mb-3 px-3' >
                {
                    errorArray.map((error) => (
                        <li className=' text-xs text-red-600 '>{error}</li>
                    ))
                }
            </div>

            <div>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Timeline size='sm' active={active} onStepClick={stepClick} orientation="vertical">
                            {steps.map((step, index) => (
                                <Timeline.Item bullet={<p className=' text-xs font-semibold'>{index + 1}</p>} key={index} title={step.title} description={step.description}>
                                    {active === index && (
                                        <Step
                                            content={step.content}
                                            onNext={nextStep}
                                            onPrev={prevStep}
                                            isLastStep={active === steps.length - 1}
                                            currentStep={active}
                                            stepNavigator={step?.navigator}
                                        />
                                    )}
                                </Timeline.Item>
                            ))}
                        </Timeline>


                        <div className=' flex justify-end'>
                            <Group position="center">
                                <Button onClick={() => closeModal(prv => !prv)} size='xs' variant="default" radius={"md"} >Close</Button>
                                {
                                    active < 4 && (
                                        <Button disabled={!isCompleted} loading={crateDeepLinkLttl.isPending} radius={"md"} type={"submit"} size='xs' >Submit</Button>
                                    )
                                }
                            </Group>
                        </div>
                    </form>
                </FormProvider>
            </div>

            <Modal
                opened={openAddApp}
                onClose={setOpenAddApp}
                title={<span className=' font-semibold text-xl'>Register Your {capitalize(openAddApp)} App </span>}
                fullScreen
                transitionProps={{ transition: 'slide-left', duration: 300 }}
            >
                <AddApplication selectedDomain={selectedDomain} closeModal={setOpenAddApp} os={openAddApp} />
            </Modal>
        </div>
    );
}