import React, { useState, useEffect } from 'react';
import { DatePicker, DateInput } from '@mantine/dates';
import PrimaryDialog from './shared/PrimaryModal';
import { Button } from '@mantine/core';
import { CiCalendar, CiCalendarDate } from 'react-icons/ci';
import { RxCross1 } from "react-icons/rx";
import Label from './shared/Label';
import { formatDateRange } from '../helper/formateDateRange';
import { useSearchParams } from 'react-router-dom';


const getTodayStart = () => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return now;
};

const getOneMonthAgo = () => {
    const today = new Date();
    const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    return oneMonthAgo;
};

const getWeekDates = () => {
    const today = new Date();
    const endOfWeek = new Date(today);
    endOfWeek.setHours(23, 59, 59, 999); // Set to the last millisecond of today

    // Calculate start of the week (7 days ago from today)
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - 7);
    startOfWeek.setHours(0, 0, 0, 0); // Set to midnight

    return { startOfWeek, endOfWeek };
};

function FilterByDate() {
    const today = new Date();
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState([today, today]);
    const [filterType, setFilterType] = useState(null);
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [filterApplied, setFilterApplied] = useState(null)
    const [isClear, setIsClear] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()


    useEffect(() => {

        if (filterType) {
            let start, end;
            const { startOfWeek, endOfWeek } = getWeekDates();

            switch (filterType) {
                case 'day':
                    start = getTodayStart();
                    end = today;
                    break;
                case 'month':
                    start = getOneMonthAgo();
                    end = today;
                    break;
                case 'year':
                    start = new Date(today.getFullYear(), 0, 1);
                    end = today;
                    break;
                case 'week':
                    start = startOfWeek
                    end = endOfWeek;
                    break;
                default:
                    start = null;
                    end = null;
            }

            setStartDate(start);
            setEndDate(end);
            setValue([start, end])
            setIsClear(false)
        }

    }, [filterType]);


    const handleDateRange = (value) => {

        setValue(value)
        if (value[0] && value[1]) {
            setStartDate(value[0]);
            setEndDate(value[1]);
        }
        setIsClear(false)

    }

    const handleFilterClick = (type) => {
        if (filterType === type) return false
        setFilterType(type);
        setValue([null, null]); // Reset date range when filter type is selected
    };

    const handleClearFilter = () => {
        setFilterType(null);
        setValue([null, null]);
        setStartDate(today);
        setEndDate(today);
        setFilterApplied(null)
        setIsClear(true)
        searchParams.delete("filterTo")
        searchParams.delete("filterFrom")
        setSearchParams(searchParams);

    };

    const applyFilter = () => {
        if (isClear) {
            setOpen(false)
            return;
        }
        setSearchParams({ filterTo: value[0], filterFrom: value[1] })
        const dateRange = formatDateRange(value)
        setFilterApplied(dateRange)
        setOpen(false);
    }

    const clear = (e) => {
        e.stopPropagation();
        handleClearFilter()

        searchParams.delete("filterTo")
        searchParams.delete("filterFrom")
        setSearchParams(searchParams);
    }





    return (
        <div>
            <Button variant='outline' size="compact-md" onClick={() => setOpen(prv => !prv)} leftSection={<CiCalendarDate />}>
                {
                    filterApplied ? <div className=' flex gap-2'>
                        {filterApplied}
                        <RxCross1 onClick={clear} />
                    </div> : "Filter By Date"
                }
            </Button>
            <PrimaryDialog open={open} onClose={() => setOpen(prv => !prv)} title={<Label className=" font-extrabold" size={20} label={"Filter by Created Date"}></Label>}>
                <div className='p-2'>
                    <div className='flex gap-2 flex-col'>
                        <Label size={14} label={"Filter By last"}></Label>
                        <Button.Group>
                            <Button size='xs' className='rounded-sm' variant="outline" onClick={() => handleFilterClick('day')}>Day</Button>
                            <Button size='xs' variant="outline" onClick={() => handleFilterClick('week')}>Week</Button>
                            <Button size='xs' variant="outline" onClick={() => handleFilterClick('month')}>Month</Button>
                            <Button size='xs' className='rounded-sm' variant="outline" onClick={() => handleFilterClick('year')}>Year</Button>
                        </Button.Group>
                    </div>
                    <div className='py-5 flex flex-col gap-2'>
                        <Label size={14} label={"Or by custom date range:"}></Label>
                        <div className='flex items-center gap-2'>
                            <DateInput
                                size="sm"
                                rightSection={<CiCalendar />}
                                value={startDate}
                                onChange={(date) => handleDateRange([date, endDate])}
                                maxDate={today}
                                level='month'
                            />
                            <span>To</span>
                            <DateInput
                                size="sm"
                                value={endDate}
                                onChange={(date) => handleDateRange([startDate, date])}
                                maxDate={today}
                                level='month'
                                rightSection={<CiCalendar />}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col items-center'>
                        <DatePicker
                            size='md'
                            type="range"
                            allowSingleDateInRange
                            value={value}
                            onChange={handleDateRange}
                            maxDate={today}
                            level='month'
                            defaultValue={[today, today]}

                        />
                    </div>
                    <div className='flex justify-end items-center gap-2 mt-3'>
                        <Button variant='transparent' leftSection={<RxCross1 />} onClick={handleClearFilter}>Clear Filter</Button>
                        <Button onClick={applyFilter}>Apply</Button>
                    </div>
                </div>
            </PrimaryDialog>
        </div>
    );
}

export default FilterByDate;
