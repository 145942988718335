import { basicToken } from "../../helper/basicAuth";

export function setupRequestInterceptor(instance) {
  instance.interceptors.request.use(
    function (config) {
      const { username, password } = JSON.parse(
        localStorage.getItem("user")
      ) ?? { username: null, password: null };
      const token = basicToken(username, password);
      config.headers.Authorization = `Basic ${token}`;
      return config;
    },
    function (error) {
      console.error("Request Error:", error);
      return Promise.reject(error);
    }
  );
}
