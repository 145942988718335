import { getAppIdentifierById, getStoreIdById } from "./getAppIdentifierById";

const deepLinkHost = process.env.REACT_APP_DEEP_LINK_SERVER;

const setURLParam = (urlInstance, key, value) => {
  if (!urlInstance || !key || !value) {
    return false;
  }

  urlInstance.searchParams.set(key, value);
  return true;
};

export function generateDeepLink(input, apps) {
  const urlInstance = new URL(deepLinkHost);
  setURLParam(urlInstance, "link", input.deep_link_url);

  if (input.android_app_id) {
    const androidIdentifier = getAppIdentifierById(input.android_app_id, apps);
    if (androidIdentifier) {
      setURLParam(urlInstance, "apn", androidIdentifier);
    }
    if (input.android_custom_url === "android_custom_url") {
      setURLParam(urlInstance, "afl", input.android_redirect_url);
    }
  }

  if (input.apple_app_id) {
    const appleIdentifier = getAppIdentifierById(input.apple_app_id, apps); // get the app package name | bundle id
    const storeId = getStoreIdById(input.apple_app_id, apps); // get Store id

    if (appleIdentifier && storeId) {
      setURLParam(urlInstance, "ibi", appleIdentifier);
      setURLParam(urlInstance, "isi", storeId);
    }
    if (input.apple_custom_url === "apple_custom_url") {
      setURLParam(urlInstance, "ifl", input.apple_redirect_url);
    }
  }
  setURLParam(urlInstance, "utm_campaign", input.utm_campaign);
  setURLParam(urlInstance, "utm_medium", input.utm_medium);
  setURLParam(urlInstance, "utm_source", input.utm_source);
  setURLParam(urlInstance, "meta_title", input.meta_title);
  setURLParam(urlInstance, "meta_description", input.meta_description);
  setURLParam(urlInstance, "meta_image", input.meta_image);

  const encodedDeepLink = urlInstance.toString();
  return encodedDeepLink;
}
