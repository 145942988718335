import { useEffect, useContext } from "react";
import MoonIcon from "../assets/icons/MoonIcon";
import SunIcon from "../assets/icons/SunIcon";
import { Context } from "../context/Context";
import { useMantineColorScheme } from "@mantine/core";

const DarkMode = () => {
  const { theme, setTheme } = useContext(Context);
  const { setColorScheme, clearColorScheme } = useMantineColorScheme();

  useEffect(() => {
    // Add or remove the 'dark' class based on the theme state
    if (theme === "dark") {
      document.body.classList.add("dark");
      localStorage.setItem("theme", "dark");
      document.body.style.background = "#000";
    } else {
      document.body.classList.add("light");
      document.body.style.background = "#F3F2F1";
      localStorage.setItem("theme", "light");
    }

    // Clean up the effect
    return () => {
      document.body.classList.remove("dark");
    };
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    setColorScheme((t) => t === 'light' ? 'dark' : "light")
  };

  return (
    <div className="flex gap-2 ">
      {theme === "light" ? (
        <div
          onClick={toggleTheme}
          className="cursor-pointer hover:bg-gray-200 active:scale-95 animation-transition duration-300 rounded-full p-1"
        >
          <MoonIcon />
        </div>
      ) : (
        <div
          onClick={toggleTheme}
          className="cursor-pointer hover:bg-gray-200 active:scale-95 animation-transition duration-300 rounded-full p-1"
        >
          <SunIcon />
        </div>
      )}
    </div>
  );
};

export default DarkMode;
