import React, { useEffect } from "react";
import InputControl from "../shared/InputControl";
import { useForm, FormProvider } from 'react-hook-form';
import Button from "../shared/Button";
import TagInput from '../shared/TagInput'
import { lttlService } from "../../service/module/lttlService";
import { getSortCodeFromURL } from "../../helper/sortCodeExtractor";
import { getShortCodeDomainFromURL } from "../../helper/extractShortCodeDomain";
import { linkService } from "../../service/module/linkService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { calculateDaysDifference } from "../../helper/dayDifference";
import { toast } from "react-toastify";
import { makeURL } from "../../helper/makeUrl";
import { regexURL } from "../../utils/Regex";

const EditView = ({ close, editData }) => {
  const methods = useForm({
    defaultValues: {
      long_url: "",
      title: "",
      description: "",
      tags: [""],
    }
  });
  const queryClient = useQueryClient();


  const mutation = useMutation({
    mutationKey: ["EditQrCode"],
    mutationFn: async (data) => {
      const shortCode = data?.short_url.split('/').pop();
      const response = await lttlService.updateShortURL(shortCode, {
        url: data.long_url,
        daysToExpire: data.expirationDays,
      });

      const { expiryDate, longUrl, shortUrl } = response;
      const sortCode = getSortCodeFromURL(shortUrl);
      const shortCodeDomain = getShortCodeDomainFromURL(shortUrl);

      const makeRequest = {
        long_url: longUrl,
        short_code: sortCode,
        short_code_domain: shortCodeDomain,
        title: data.title,
        description: data.description,
        tags: data.tags?.toString(),
        expiry_date: expiryDate,
        is_qr: 1,
      };

      return await linkService.updateLinkById(editData.id, makeRequest);
    },
    onError: (error) => {
    }
  });

  const onSubmit = async (data) => {
    mutation.mutate(data, {
      onSuccess: (data) => {
        queryClient.invalidateQueries('getLinks');
        close((prv) => !prv);
        toast.success(data.message)
      },
      onError: () => {
        toast.error("Something went wrong")
      }
    })
  };


  useEffect(() => {
    const { long_url, title, description, tags, expiry_date, short_code_domain, short_code } = editData;
    if (editData && methods) {
      const day = calculateDaysDifference(expiry_date)
      methods.reset({
        long_url: long_url,
        title: title,
        short_url: makeURL(short_code_domain, short_code),
        description: description,
        tags: tags?.split(",") ?? [],
        expirationDays: day
      })
    }
  }, [editData, methods])

  // console.log("Edit", editData)


  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <InputControl
          label="Long Url"
          name="long_url"
          hintText="Enter the complete URL, e.g. https://example.com"
          required={true}
          maxLength={10000}
          rules={{ maxLength: [10000, "Long Url cannot be more than 10000 characters!"], validate: [regexURL, "Invalid URL Please enter a valid URL"] }}
        />
        <InputControl
          label="Short Url"
          name="short_url"
          hintText=""
          required={false}
          maxLength={200}
          disabled={true}
        />
        <InputControl
          label="Title"
          name="title"
          hintText="Enter Title"
          maxLength={100}
          rules={{ maxLength: [99, "Title cannot more then 100 character"] }}
        />
        <InputControl
          label="Description"
          name="description"
          hintText="Enter description (optional)"
          maxLength={250}
          rules={{ maxLength: [249, "Description cannot more then 240 character"] }}
        />
        <div className="mt-3">
          <TagInput
            name="tags" label="Press Enter to submit a tag" placeholder="Enter tag" />
        </div>
        <InputControl
          label="Expiration Days"
          name="expirationDays"
          hintText="Enter number of days: e.g.,30"
          type="number"
          isOptional={true}
          rules={{ maxValue: [366, "Expiry Days cannot be greater than 365 days"], minValue: [1, "The daysToExpire field must be a positive integer."] }}
        />
        <div className="flex justify-end gap-2 mt-8">
          <Button variant="default" onClick={() => close(false)} label={"Cancel"} />
          <Button disabled={mutation.isPending} type="submit" label={"Update"} />
        </div>
      </form>
    </FormProvider>
  )
};

export default EditView;
